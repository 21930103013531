import React from "react"
import { Link } from "gatsby"
import { FaGooglePlay, FaApple } from "react-icons/fa"

import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import Image from "reusecore/Image"
import { SectionTitle, SectionBackground } from "reusecore/SectionTitle"

import aboutImage from "assets/images/2023/defi_1_1_33pct.png"
import investingTrackImg from "assets/images/2023/invest_1_3.png"
import nftsId from "assets/images/2023/nfts_metaverse_did_3.png"
import refiId from 'assets/images/2023/refi_x_impact_1.png'
import daosId from "assets/images/2023/daos_1.png"
import workshop from "assets/images/2023/workshops_2.png"
import AboutSectionWrapper, {ConfAgendaWrapper} from "./about.style"
import {titleCase} from "../../utils/forms.utils.js";


const tracks = [
  {
    title: 'Real World Assets 🌎',
    titleCase: true,
    image: aboutImage,
    description: `We'll explore the transformative power of blockchain technology in the world of finance, with a particular focus on Africa and developing economies. Under the overarching theme of 'Unlocking Value: Innovations in Real-World Asset (RWA) Financing 🌎`,
    slots: [
        { timeStart: '9:20AM', timeEnd: '10:20AM', title: 'Tokens From The Tropics: Elevating Real-World Assets in Africa'},
      { timeStart: '10:30AM', timeEnd: '11:15AM', title: 'Emerging Horizons: The Current Landscape of RWA in Developing Economies '},
      { timeStart: '11:30AM', timeEnd: '12:15PM', title: 'African Web 3 VC Landscape: Surviving the Winter'},
      { timeStart: '2:20PM', timeEnd: '3:20PM', title: 'Beyond VC ~ New Funding Models and Mechanisms'},
    ]
  },{
    title: 'Real Impact & Use Cases 🌟 ',
    titleCase: true,
    image:  nftsId,
    description: 'Join us for a mind-bending journey through the world of GameFi, ReFi, On-Chain Agriculture, and the ever-expanding NFT universe. Discover how these cutting-edge technologies are transforming our financial landscape, empowering climate action, and bridging the gap to financial inclusion.🌟',
    slots: [
        { timeStart: '9:20AM', timeEnd: '10:20AM', title: 'The Future of GameFi: Adoption Trends & Ecosystem Growth'},
      { timeStart: '10:30AM', timeEnd: '11:15AM', title: 'All About ReFi: Empowering Climate Action & Financial Inclusion'},
      { timeStart: '11:30AM', timeEnd: '12:15PM', title: 'Of 0x and Oxen: On-Chain Agriculture'},
      { timeStart: '13:30PM', timeEnd: '14:15PM', title: 'Beyond Pixels: The Expanding Universe of NFTs, the Metaverse, and More'},
    ]
  },{
    title: 'Real People (Identity) & Communities (DAOs) 🤝',
    titleCase: false,
    image: daosId,
    description: `Join us to delve into Digital Tribes, human stories behind tech, bridging the digital gap, and fostering inclusivity in blockchain. Don't miss it! 🚀`,
    slots: [
        { timeStart: '9:20AM', timeEnd: '10:20AM', title: 'Digital Tribes: The Rise of Online Communities '},
      { timeStart: '10:30AM', timeEnd: '11:15AM', title: 'Heartbeats & Hashrates: Human Stories Behind the Tech '},
      { timeStart: '11:30AM', timeEnd: '12:15PM', title: 'Connect & Converse: Building Bridges in the Digital Age '},
      { timeStart: '12:30PM', timeEnd: '02:15PM', title: 'Unity in the Chain: Fostering Inclusivity in Blockchain '},
    ]
  },{
    title: 'Real Tech Advances 🚀',
    titleCase: false,
    image: workshop,
    description: ` Discover the latest in Account Abstraction, Wallet Infrastructure, Crypto Cybersecurity, and the delicate balance between Liquidity and Privacy. Get ready for the L2 Showdown as we embrace an omnichain future. Join us on the cutting edge! 🔥`,
    slots: [
        { timeStart: '9:20', timeEnd: '10:20', title: 'The State of Account Abstraction: How account abstraction is racing from theory to practice '},
        { timeStart: '11:30', timeEnd: '13:20', title: 'Get Your Hand Out My Wallet! Advances in Wallet Infrastructure & Crypto Cybersecurity  '},
        { timeStart: '13:50', timeEnd: '14:20', title: 'Shadows & Streams: Balancing Liquidity with Privacy'},
      { timeStart: '14:30AM', timeEnd: '15:15', title: 'L2 Showdown! Embracing our omnichain future'}
    ]
  }
  // ,{
  //   title: 'NFTs & Digital Identity',
  //   titleCase: false,
  //   image: investingTrackImg,
  //   description: 'A metaverse experience and immersive conversation',
  //   slots: [
  //       { timeStart: '9:20AM', timeEnd: '10:20AM', title: 'Digital identity (NFT Domains Decentralized identity)'},
  //     { timeStart: '10:30AM', timeEnd: '11:15AM', title: 'NFT Art and artSTORM'},
  //     { timeStart: '11:30AM', timeEnd: '12:15PM', title: 'Digital Communities'},
  //   ]
  // },
  // {
  //   title: 'DAOs',
  //   titleCase: false,
  //   image: refiId,
  //   description: ``,
  //   slots: [
  //       { timeStart: '9:20AM', timeEnd: '10:20AM', title: 'Work in Web3, How to get started'},
  //     { timeStart: '10:30AM', timeEnd: '11:15AM', title: 'Inclusivity'},
  //     { timeStart: '11:30AM', timeEnd: '12:15PM', title: 'The Good And Bad, Work Life Balance'},
  //     { timeStart: '11:30AM', timeEnd: '12:15PM', title: 'DAOs Ni Nini? Governance and DAO Formation'},
  //   ]
  // }
]
const About = () => {


  return (
    <AboutSectionWrapper id="about">
      <Container>
        <Row>
          <Col className="lg-5 md-6 sm-12">
            <Image
              // src={zebraStripeBgImage}
              src={`https://storage.googleapis.com/sme-connect-283212.appspot.com/speakers-group-1.png`}
              className="about-image"
              alt="ethsafari about image"
            />
          </Col>
          <Col className="lg-6 offset-lg-1 md-6 sm-12">
            <Box className="about-right-content">
              <SectionTitle>
                <SectionBackground>
                  <Heading as="h1">
                    Protecting investor with high security
                  </Heading>
                </SectionBackground>

                <Text>
                  Intended village pay like…. Discipline would, interesting that
                  I off easier bathroom covered project the away, man you their
                  by a temple prisoners. Have as her of your of in many,
                  searched had class. Had with one entire children away. Problem
                  got boss.
                </Text>
              </SectionTitle>

              <Box className="about-list">
                <Text as="span">75% Distributed to Community.</Text>
                <Text as="span">13% Reserved Funding.</Text>
                <Text as="span">9% Founders and Team Management.</Text>
              </Box>

              <Box className="about-btn-wrapper">
                <Link to="#" className="btn btn-fill">
                  {" "}
                  <FaGooglePlay /> Google Play
                </Link>
                <Link to="#" className="btn">
                  <FaApple /> Apple Store
                </Link>
              </Box>
            </Box>
          </Col>
        </Row>
      </Container>
    </AboutSectionWrapper>
  )
}

const ConferenceProgram = () => {
  const program = tracks.map((track, index) => {
    const imageCol = (<Col className="lg-5 md-6 sm-12">
      <Image
          // src={zebraStripeBgImage}
          src={track.image}
          className="about-image"
          alt="ethsafari about image"
      />
    </Col>)
    const detailsCol = (<Col className="lg-6 offset-lg-1 md-6 sm-12">
      <Box className="about-right-content">
        <SectionTitle>
          <SectionBackground>
            <Heading FontFamily={`Poppins-SemiBold`} as="h1">
              {track.titleCase ? titleCase(track.title) : track.title }
            </Heading>
          </SectionBackground>

          <Text FontFamily={`Poppins`}>
            {track.description}
          </Text>
        </SectionTitle>

        <Box className="about-list">
          {track.slots.map((slot) => {
            return ( <Text FontFamily={`Poppins`} as="span">{slot.title}</Text>)
          })}
        </Box>

        {/*<Box className="about-btn-wrapper">*/}
        {/*  <Link to="#" className="btn btn-fill">*/}
        {/*    {" "}*/}
        {/*    <FaGooglePlay /> Google Play*/}
        {/*  </Link>*/}
        {/*  <Link to="#" className="btn">*/}
        {/*    <FaApple /> Apple Store*/}
        {/*  </Link>*/}
        {/*</Box>*/}
      </Box>
    </Col>)
    return (<Row style={{margin: '2rem auto'}}>
      {((index + 1) % 2) ? <>{imageCol} {detailsCol}</>: <> {detailsCol} {imageCol} </> }
    </Row>)
  })
  const mobileProgram = tracks.map((track, index) => {
    const imageCol = (<Col className="lg-5 md-6 sm-12">
      <Image
          // src={zebraStripeBgImage}
          src={track.image}
          className="about-image"
          alt="ethsafari about image"
      />
    </Col>)
    const detailsCol = (<Col className="lg-6 offset-lg-1 md-6 sm-12">
      <Box className="about-right-content">
        <SectionTitle>
          <SectionBackground>
            <Heading FontFamily={`Poppins-SemiBold`} as="h1">
              {track.titleCase ? titleCase(track.title) : track.title }
            </Heading>
          </SectionBackground>

          <Text FontFamily={`Poppins`}>
            {track.description}
          </Text>
        </SectionTitle>

        <Box className="about-list">
          {track.slots.map((slot) => {
            return ( <Text FontFamily={`Poppins`} as="span">{slot.title}</Text>)
          })}
        </Box>

        {/*<Box className="about-btn-wrapper">*/}
        {/*  <Link to="#" className="btn btn-fill">*/}
        {/*    {" "}*/}
        {/*    <FaGooglePlay /> Google Play*/}
        {/*  </Link>*/}
        {/*  <Link to="#" className="btn">*/}
        {/*    <FaApple /> Apple Store*/}
        {/*  </Link>*/}
        {/*</Box>*/}
      </Box>
    </Col>)
    return (<Row style={{margin: '2rem auto'}}>
      {<>{imageCol} {detailsCol}</>}
    </Row>)
  })
  return (
      <ConfAgendaWrapper id={'confAgenda'} >
        <Container className="conf-agenda">
          <Row><Heading FontFamily={'Poppins-Bold'} as={'h1'}> Conference Theme - Get Real 🎯 </Heading></Row>
          <div className={'hidden-xs'}>{program}</div>
          <div className={'hidden-md hidden-xl hidden-lg'}>{mobileProgram}</div>
        </Container>
      </ConfAgendaWrapper>
  )
}

export {ConferenceProgram}
export default About
