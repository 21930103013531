import React from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon,
} from "../../reusecore/Accordion/index.js"
import { Container, Row, Col } from "reusecore/Layout"

import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io"

import { SectionTitle, SectionBackground } from "reusecore/SectionTitle"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"

import faqImage from "assets/images/2023/FAQ_1.png"

import data from "../../assets/data/faq/index.js"
import "react-tabs/style/react-tabs.css"
import FaqSectionWrapper from "./faq.style"
import Text from "../../reusecore/Text/index.js";
import {ethSafariColors} from "../../assets/theme/theme.js";
import Image from "../../reusecore/Image/index.js"

const Faq = () => {
  return (
    <FaqSectionWrapper id="faq"> 
        <Container>
          <Row>
            <Col className="md-6 xs-12">
              <SectionTitle>
                <SectionBackground>
                  <Heading FontFamily="Poppins-SemiBold">Frequently asked questions</Heading>
                </SectionBackground>
                <Text FontFamily="Poppins">
                  Common questions you might have about ETHSafari
                </Text>
              </SectionTitle>
            </Col>
          </Row>

          <Row>
            <Col className="md-6 xs-12">
              <Box className="faq-content">
                <Tabs>
                  <TabList>
                    {data.faq.map((tab, i) => (
                      <Tab key={i}>{tab.name}</Tab>
                    ))}
                  </TabList>

                  {data.faq.map((tabItem, index) => (
                    <TabPanel key={index}>
                      <Accordion>
                        {tabItem.items.map((item, index) => (
                          <AccordionItem key={index}>
                            <AccordionTitle>
                              <Heading as="h4" FontFamily={'Poppins-SemiBold'}>{item.title}</Heading>
                              <IconWrapper>
                                <OpenIcon>
                                  <IoIosArrowForward />
                                </OpenIcon>
                                <CloseIcon>
                                  <IoIosArrowDown />
                                </CloseIcon>
                              </IconWrapper>
                            </AccordionTitle>
                            <AccordionBody>
                              <Text FontFamily="Poppins" TextColor={ethSafariColors.whiteTone}> {item.body}</Text>
                              {item?.children}
                            </AccordionBody>
                          </AccordionItem>
                        ))}
                      </Accordion>
                    </TabPanel>
                  ))}
                </Tabs>
              </Box>
            </Col>
            <Col className="md-5 offset-md-1 offset-xs-0 xs-12">
              <Image
                src={faqImage}
                alt="ETHSafari faq image"
                className="faq-image"
              />
            </Col>
          </Row>
        </Container> 
    </FaqSectionWrapper>
  )
}

export default Faq
