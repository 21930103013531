import styled from "styled-components";

const BlocktrainAnimationWrapper = styled.div`
  position: absolute;
  .chatDeFi-container {
    
  }

`;

export default BlocktrainAnimationWrapper;