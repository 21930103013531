import React from 'react';
import CustomIconWrapper from "./CustomIcon.style.js";

const FaCustomIcon = ({className, children}) => {
    return (
        <CustomIconWrapper className={className ? className : 'icon-custom'}>
            {children}
        </CustomIconWrapper>
    );
}

export default FaCustomIcon;