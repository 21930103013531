import styled from 'styled-components';
import {ethSafariColors} from "../../assets/theme/theme.js";


const ServiceSectionWrapper = styled.div`

  padding: 0 0 0.2rem 0;

  .service-item-wrapper {
    //padding: 25px 32px;
    padding: 0.6rem 0.8rem;

    border-radius: 10px;
    background-color: ${props => props.bgAgenda ? props.bgAgenda : 'white'};

    .service-item {
      padding: 15px 0;
      font-weight: bold;
      border-radius: 8px;
      transition: all 0.3s ease-in;

      &:hover {
        background: inherit;
      }

      img {
        height: 80px;
        margin-bottom: 20px;
      }

      h3 {
        font-size: 20px;
        font-weight: 500;
        margin: 0 0 14px 0;
      }
    }

    p {
      margin: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    .service-item {
      //margin-bottom: 30px;
    }

    .service-item-wrapper {
      //padding: 45px 32px 25px 32px;
      padding: 2rem 0.93rem 1rem 0.93rem;
    }
  }
  @media only screen and (max-width: 480px) {
    //padding: 55px 0 50px 0;
    //padding: 1.2rem 0 1.2rem 0;
  }
`

export default ServiceSectionWrapper;