import styled from "styled-components"
import banner1BG from "assets/images/2023/BgMesh1.png"
const BannerWrapper = styled.div`
  position: relative;
  //overflow-y: scroll;
  padding: 10px 0 20px 0;
  min-height: 100vh;
  /* background: url('${banner1BG}') no-repeat; */
  background: url('${banner1BG}') no-repeat;
  background-size: cover;
  transition: background-image .3s ease-in-out;
  -moz-transition: background-image .3s ease-in-out;
  -ms-transition: background-image .3s ease-in-out;
  -o-transition: background-image .3s ease-in-out;
  -webkit-transition: background-image .3s ease-in-out;
  /* animation: landingBg 60s infinite; */

  .highlighted {
  padding: 1rem 1.75rem;
  border-radius: 0.75rem;
  /* background: linear-gradient(to right, #fda1fd, #fd56fd) 0 50% no-repeat; */
  /* background-size: .75% 85%; */
  transition: all .5s ease-in;
  -moz-transition: all .3s ease-in;
  -ms-transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;
}

.section__stripes {
    position: absolute;
    transform: rotate(-90deg);
    &.top-left {
      left: -313px;
      top: 275px;
      height: 150px;
      z-index: 3;
    }
}

.dark-text-stroke {
  
}

.highlighted:hover {
  /* background-size: 100% 85%; */
  background: rgba(255, 255, 255, 0.2); 
  backdrop-filter: blur(8px);
}

  @keyframes pulse {
    from {
      transform: scale3d(1, 1, 1);
    }
    25% {
      transform: scale3d(1.05, 1.05, 1.05);
    }
    50% {
      transform: scale3d(1, 1, 1);
    }
    to {
      transform: scale3d(1, 1, 1);
    }
  }

  @-webkit-keyframes arrowInOut {
    0%, 100% {
      opacity: 0;
    }
    30%, 80% {
      opacity: 1;
    }
  }
  @keyframes arrowInOut {
    0%, 100% {
      opacity: 0;
    }
    30%, 80% {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes shakeX {
    from,
    to {
      transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
      transform: translate3d(-10px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
      transform: translate3d(10px, 0, 0);
    }
  }

  @keyframes jello {
    from,
    11.1%,
    to {
      transform: translate3d(0, 0, 0);
    }

    22.2% {
      transform: skewX(-12.5deg) skewY(-12.5deg);
    }

    33.3% {
      transform: skewX(6.25deg) skewY(6.25deg);
    }

    44.4% {
      transform: skewX(-3.125deg) skewY(-3.125deg);
    }

    55.5% {
      transform: skewX(1.5625deg) skewY(1.5625deg);
    }

    66.6% {
      transform: skewX(-0.78125deg) skewY(-0.78125deg);
    }

    77.7% {
      transform: skewX(0.390625deg) skewY(0.390625deg);
    }

    88.8% {
      transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    }
  }


  .banner_landingImg {
    z-index: ${props => props.bgImage?.styleOpts?.zIndex};
    /* width: ${props => props.bgImage?.styleOpts?.width}; */
    /* left: ${props => props.bgImage?.styleOpts?.left};
    top: ${props => props.bgImage?.styleOpts?.top}; */
    margin: 0 auto;
    min-width: ${props => props.bgImage?.styleOpts?.minWidthLg};  
  }

  .landingImgContainer {
    min-height: 20rem;
  }
  .banner__thumb1 {
    // position: relative;
    left: -2%;
    top: 80px;
    width: 85%;
    // animation: pulse 4s infinite;
    animation: fadeIn 5s;
    z-index: 30;
    // opacity: 0;
    // animation-delay: 1s;
    // -webkit-animation: arrowInOut 8s linear forwards;
    // animation: arrowInOut 8s linear forwards infinite;
    // z-index: 30;
    // elevation: 5,
    // backgroundColor: 'red', width: 100, height: 100, borderRadius: 50,
    // shadowColor: "black",
    // shadowOffset: { height: 2},
    // shadowOpacity: 0.3,
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .banner__thumb2 {
    position: absolute;
    left: 59.2%;
    top: -23.2px;
    width: 6%;
    animation: fadeIn 5s;
    z-index: 30;
    // opacity: 0.2; Removed opacity and used z-index instead to place the zebra behind the ETH safari text
    // animation: pulse 4s infinite;
    // animation-delay: 1s;
    transform: rotate(-70deg);
  }

  .banner__thumb3 {
    position: relative;
    left: 27%;
    top: 90px;
    width: 38%;
    animation: pulse 4s infinite;
    animation-delay: 2s;
  }

  .section__particle {
    position: absolute;

    &.top-left {
      left: -98px;
      top: -60px;
      height: 150px;
    }

    &.top-right {
      top: 65px;
      right: 28px;
      height: 74px;
    }

    &.bottom-left {
      bottom: 0px;
      left: 15px;
      height: 150px;
    }

    &.two {
      top: 6px;
      left: 140px;
      height: 87px;
    }

    &.bottom-right {
      right: 49px;
      bottom: 129px;
      height: 200px;
    }
  }

  .banner-content {
    .heading1 {
      margin: 0 0 2.5rem 0;
      // margin: 0 0 200px 0;
      font-size: 30px;
      line-height: 50px;
      font-weight: 900;
      color: black;
      margin-top: 2rem;
      // margin-top: 120px;
      animation: fadeIn 10s;
      font-family: 'Poppins-ExtraBold';
      // animation-delay: 10s;
    }

    .heading2 {
      margin: 0 0 2.5rem 0;
      font-size: 30px;
      color: black;
      line-height: 50px;
      font-weight: 900;
      margin-top: 1rem;
      font-family: 'Poppins-SemiBold';
    }
  }

  .heading3 {
    margin: 0 0 1.5rem 0;
    // margin: 0 0 200px 0;
    font-size: 30px;
    line-height: 50px;
    font-weight: 900;
    color: black;
    margin-top: 2rem;
    // margin-top: 120px;
    animation: fadeIn 10s;
    font-family: 'Poppins-SemiBold';
    // animation-delay: 10s;
  }

  .text {
    margin-bottom: 55px;
  }

  .banner-btn, .banner-alt-btn {
    margin-top: 0px;
    a {
      min-width: 13.75rem;
      font-size: 1.5rem;
      font-weight: bold;
      color: #ffffff;
      padding-left: 50px;
      padding-right: 80px;
      // padding: 0px 0px;
      display: flex;
      margin: 0.5rem 0;
      // align-items: row;
      // justify-content: row;
      // flex: 1;
      // justifyContent: center;
      // flex-direction: row;
      // align-items: center;
      // justify-content: space-evenly;
      border: none;

      svg {
        margin-right: 10px;
      }
    }

    .image-btn {
      // animation: pulse 4s infinite;
      // animation: fadeIn 40s;
      // animation: shakeX 10s infinite;
      animation: jello 5s infinite;
    }

    .btn-fill {
      transition: all 0.3s ease-in;
      background: rgb(255, 138, 0);
      background: linear-gradient(93deg,
        // rgba(255,113,255,1) 0%,
        // rgba(255, 166, 0, 1) 52%,
        // rgba(153, 207, 28, 1) 100%
      rgba(3, 3, 3, 1) 0%,
        // rgba(255, 166, 0, 1) 52%,
      rgba(251, 251, 251, 1) 100%);

      &:hover {
        background: linear-gradient(93deg,
        rgba(251, 251, 251, 1) 0%,
        rgba(3, 3, 3, 1) 100%
          // rgba(153, 207, 28, 1) 0%,
          // rgba(255, 166, 0, 1) 52%,
          // rgba(255, 113, 255, 1) 100%
        );
      }
    }

    .btn-fill-alternate, .btn-fill-alternate-dark {
      transition: all 0.3s ease-in;
      background: #5A005F;
      // background: #FDA1FD;
      // color: #000000;
      border-radius: 3rem;
      font-family: 'Poppins-SemiBold';
      justify-content: center;
      // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      &:hover {
        background: white;
        // background: #FDA1FD;
        // color: #005B5C;
        color: #FDA1FD;
        // color: white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }

  .coin-info {
    display: flex;
    align-items: center;

    div {
      display: flex;
      align-items: center;
      margin: 12px 25px 5px 25px;

      .text {
        margin: 0;
      }

      img {
        width: 25px;
        margin-right: 15px;
      }
    }
  }

  .description {
    position: relative;
    display: block;
    line-height: 70px;
    font-size: 30px;
    font-color: '#FAE701';
    padding-left: 30px;
    margin: 200px 100px 17px 0;

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 3px;
      content: "";
      background: #b096df;
    }
  }


.btn-text {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-width: 14rem;
  margin: 0;
  margin-bottom: 0;
  font-size: 1.3rem
}

.banner-text-row {
  margin-top: 4rem;
}





@keyframes bounce-up-down {
  from {
    -webkit-transform: rotate(0deg);
    -webkit-transform-origin: 200px 200px;
    transform-origin: 200px 200px;
  }
  33% {
    -webkit-transform: rotate(0deg);
    -webkit-transform-origin: 180px 220px;
    transform-origin: 1000px 220px;
  }
  66% {
    -webkit-transform: rotate(1deg);
    -webkit-transform-origin: 200px 200px;
    transform-origin: 1500px 200px;
  }
  to {
    -webkit-transform: rotate(0deg);
    -webkit-transform-origin: 200px 200px;
    transform-origin: 200px 200px;
  }
}

@-webkit-keyframes bounce-up-down {
  from {
    -webkit-transform: rotate(0deg);
    -webkit-transform-origin: 200px 200px;
    transform-origin: 200px 200px;
  }
  33% {
    -webkit-transform: rotate(0deg);
    -webkit-transform-origin: 180px 220px;
    transform-origin: 1000px 220px;
  }
  66% {
    -webkit-transform: rotate(1deg);
    -webkit-transform-origin: 200px 200px;
    transform-origin: 1500px 200px;
  }
  to {
    -webkit-transform: rotate(0deg);
    -webkit-transform-origin: 200px 200px;
    transform-origin: 200px 200px;
  }
}

@keyframes opacity-controll {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.6;
  }
  75% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes opacity-controll {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.6;
  }
  75% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}

#Path_46973 {
  animation: opacity-controll 1.5s infinite; /* IE 10+, Fx 29+ */
}

#Path_46974 {
  animation: opacity-controll 1.7s infinite; /* IE 10+, Fx 29+ */
}

#Path_46975 {
  animation: opacity-controll 1.9s infinite; /* IE 10+, Fx 29+ */
}

#Path_46976 {
  animation: opacity-controll 2s infinite; /* IE 10+, Fx 29+ */
}

@media only screen and (max-width: 1024px) {
  padding: 150px 0 120px 0;

  .banner_landingImg {
    min-width: ${props => props.bgImage?.styleOpts?.minWidthMd};
  }
  .banner__thumb {
    right: 0;
  }

  .banner__thumb1 {
    // position: absolute;
    left: 4%;
    width: 96%;
    top: -50px;
  }

  .banner__thumb2 {
    left: 67%;
    top: -77.5px;
    width: 10%;
  }

  .banner__thumb3 {
    left: 50%;
    width: 85%;
  }

  .banner-content {
    .heading1 {
      font-size: 45px;
      line-height: 65px;
      font-weight: 600;
    }

    .heading2 {
      font-size: 45px;
      line-height: 65px;
      font-weight: 600;
    }

    .heading3 {
      font-size: 45px;
      line-height: 65px;
      font-weight: 600;
    }
  }
}

@media only screen and (max-width: 912px) {
  padding: 150px 0 120px 0;

  .banner_landingImg {
    min-width: ${props => props.bgImage?.styleOpts?.minWidthMd};
  }
  .banner__thumb {
    display: none;
  }

  .banner__thumb1 {
    // position: absolute;
    left: 4%;
    width: 94%;
    top: -50px;
  }

  // .banner__thumb1 {
  //   position: absolute;
  //   left: 0%;
  //   width: 100%;
  //   top: -200px;
  // }
  .banner__thumb2 {
    left: 67%;
    top: -77.5px;
    width: 10%;
  }

  .banner-content {
    .heading1 {
      font-size: 30px;
      line-height: 60px;
    }

    .heading2 {
      font-size: 30px;
      line-height: 60px;
    }

    .heading3 {
      font-size: 30px;
      line-height: 60px;
    }
  }
}

@media only screen and (max-width: 768px) {
  padding: 150px 0 120px 0;

  .banner_landingImg {
    min-width: ${props => props.bgImage?.styleOpts?.minWidthSm};
  }
  .banner__thumb1 {
    position: absolute;
    left: 4%;
    width: 94%;
    top: -50px;
  }

  .banner__thumb2 {
    left: 67%;
    top: -77.5px;
    width: 10%;
  }
  

  .banner-content {
    .heading1 {
      font-size: 30px;
      line-height: 50px;
    }

    .heading2 {
      font-size: 25px;
      line-height: 50px;
    }

    .heading3 {
      font-size: 30px;
      line-height: 50px;
    }
  }

  .banner-btn, .banner-alt-btn {
    margin-top: 0px;

    a {
      font-size: 1.09rem;
    }
  }
}

@media only screen and (width: 768px) {

  .banner_landingImg {
    min-width: ${props => props.bgImage?.styleOpts?.minWidthSm};
  }
  .banner-text-row {
    margin-top: 4rem;
  }
}

@media only screen and (width: 1024px) {

  .banner_landingImg {
    min-width: ${props => props.bgImage?.styleOpts?.minWidthMd};
  }
  .banner-text-row {
    margin-top: 4rem;
  }

  .banner__thumb2 {
    top: -13.5px;
  }
}

@media only screen and (max-width: 480px) {
  padding: 150px 0 10px 0;

  .btn-text {
    font-size: 0.85rem;
  }

  .banner_landingImg {
    min-width: ${props => props.bgImage?.styleOpts?.minWidthXs};
  }
  .banner__thumb1 {
    position: absolute;
    left: 4%;
    width: 96%;
    top: -50px;
  }

  .banner__thumb2 {
    left: 67%;
    top: -67.5px;
    width: 10%;
  }

  .banner-content {
    .heading1 {
      font-size: 25px;
      line-height: 50px;

      br {
        display: none;
      }
    }

    .heading2 {
      font-size: 20px;
      line-height: 50px;

      br {
        display: none;
      }
    }

    .heading3 {
      font-size: 25px;
      line-height: 50px;

      br {
        display: none;
      }
    }

    .banner-btn, .banner-alt-btn {
      margin-top: 0px;

      a {
        font-size: 1.05rem;
      }
    }

    .text {
      br {
        display: none;
      }
    }
  }

  .section__particle {
    &.bottom-right {
      display: none;
    }
  }
}

@media only screen and (max-width: 375px) {
  padding: 150px 0 120px 0;
  .banner_landingImg {
    min-width: ${props => props.bgImage?.styleOpts?.minWidthXs};
  }
  .banner__thumb1 {
    position: absolute;
    left: 4%;
    width: 96%;
    top: -50px;
  }

  .banner__thumb2 {
    left: 67%;
    top: -67.5px;
    width: 10%;
  }

  .btn-text {
    font-size: 0.8rem;
  }

  .banner-content {
    .heading1 {
      font-size: 28px;
      line-height: 40px;
    }

    .heading2 {
      font-size: 18px;
      line-height: 40px;
    }

    .heading3 {
      font-size: 28px;
      line-height: 40px;
    }

    .description {
      font-size: 16px;
      line-height: 26px;
      padding-left: 20px;
    }

    .coin-info {
      div {
        margin: 12px 8px 5px 8px;

        img {
          margin-right: 10px;
        }
      }
    }
  }
}
`
export default BannerWrapper
