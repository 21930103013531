// import Honeycoin from "../../../assets/images/partners/Honeycoin.png"
// import Metaversal from "../../../assets/images/partners/Metaversal.png"
// import Regen from "../../../assets/images/partners/Regen.png";
// import abc from "../../../assets/images/partners/abc-logo.png"
// import abi from "../../../assets/images/partners/abi_logo-2.png"
// import adalabs from "../../../assets/images/partners/adalabs.png"
// import africoin from "../../../assets/images/partners/africoin.svg";
// import aragon from "../../../assets/images/partners/aragon.png";
// import metamask from "../../../assets/images/partners/aragon.png";
// import ethos from "../../../assets/images/2023/ethos.svg";
// import aqualabs from "../../../assets/images/2023/aqualabs.svg";
import celoMentoOpera from "../../../assets/images/partners/celoMentoOpera.png";
import digipesa from "../../../assets/images/2023/digipesa2023.png";
import base from "../../../assets/images/partners/baseBlue.png";
import coinbase from "../../../assets/images/2023/coinbase.svg";
// import algorand from "../../../assets/images/partners/algorand_full_logo_white.png"
// import asiatokenfund from "../../../assets/images/partners/asiatokenfund.png";
// import banklessAfrica from "../../../assets/images/partners/banklessAfrica.png"
// import banklessDao from "../../../assets/images/partners/bankless-logo.png"
// import bitke from "../../../assets/images/partners/bitke-white-300x259.png";
// import bitlipa from "../../../assets/images/partners/bitlipa.png"
// import blocktides from "../../../assets/images/partners/blocktides.jpg";
// import coinedition from "../../../assets/images/partners/coinedition.jpg";
// import coinstelegram from "../../../assets/images/partners/coinstelegram.png";
import cointelegraph from "../../../assets/images/partners/CT_logo_WhiteYellow_tag.png";
// import coinvoice from "../../../assets/images/partners/coinvoice.png";
// import cryptonewsz from "../../../assets/images/partners/cryptonewsz.png";
import nodo from "../../../assets/images/partners/nodo.png";
// import cryptorunners from "../../../assets/images/partners/cryptorunner-logo.png"
// import daoPlanetLogo from "../../../assets/images/partners/standerized-size/DAO-Planet-Logo.svg"
import memoi from "../../../assets/images/2023/memoi.png"
// import dsrptd from "../../../assets/images/partners/dsrptd_23.png"
// import emergo from "../../../assets/images/partners/emergo.png"
// import encodeClubLogo from "../../../assets/images/partners/standerized-size/encode_club_white.svg"
import ethAfrica from "../../../assets/images/partners/ethafrica.svg"
// import filecoin from "../../../assets/images/partners/filecoin-logo.svg"
// import filecoinFoundation from "../../../assets/images/partners/filecoin-foundation.png"
// import herDAOLogo from "../../../assets/images/partners/HER_DAO.svg"
import impactPlus from "../../../assets/images/2023/impactplus2023.png"
// import jia from "../../../assets/images/partners/jia.png"
// import juiceboxLogo from "../../../assets/images/partners/standerized-size/jb6.png"
// import kenyanWallstreet from "../../../assets/images/partners/kenyanwallstreet.png"
// import marcopolo from "../../../assets/images/partners/marcopolo-logo.png"
// import near from "../../../assets/images/partners/near.png"
// import nuzo from "../../../assets/images/partners/nuzo.png"
// import polygonLogo from "../../../assets/images/2023/polygon2023.png"
// import metamask from "../../../assets/images/partners/metamask.svg"
// import ethereumFoundation from "../../../assets/images/partners/EF_ESP_logo.png"
// import fonbnk from "../../../assets/images/2023/fonbnk.png"
// import adaverse from "../../../assets/images/2023/adaverseLogo.png"
// import celo from "../../../assets/images/partners/Celo_2023_v2.png";
import icp from "../../../assets/images/partners/IC_2023.svg"
import avalanche from "../../../assets/images/partners/avaLabs_2023.svg"
import starkware from "../../../assets/images/partners/starkware.svg"
import optimism from "../../../assets/images/partners/optimism.svg"
import lisk from "../../../assets/images/partners/lisk.svg"
import itez from "../../../assets/images/partners/itez.svg"
import streamETH from "../../../assets/images/partners/streamETH.png"
// import sharehub_logo from "../../../assets/images/partners/sharehub_logo.png"
// import syscoin from "../../../assets/images/partners/syscoin-logo.svg"
// import telos from "../../../assets/images/partners/telos.svg"
// import unstoppableDomains from "../../../assets/images/partners/unstoppable-domains.png"
import utuLogo from "../../../assets/images/partners/standerized-size/utu-light-logo-1-002.svg"
// import visa from "../../../assets/images/partners/visa.png"
// import welook from "../../../assets/images/partners/welookwhite.png"
// import worldcoin from "../../../assets/images/partners/worldcoin-black.png"

export default {
  stack: [

    // {
    //   name: "Celo",
    //   url: "https://celo.org/",
    //   imageSrc: celo,
    //   id: "celo",
    // },

    // {
    //   name: "Emurgo",
    //   url: "https://emurgo.io/",
    //   imageSrc: emergo,
    //   id: "emurgo",
    // },

    // {
    //   name: "Polygon",
    //   url: "https://polygon.technology/",
    //   imageSrc: polygonLogo,
    //   id: "polygon",
    // },
    {
      name: "Optimism",
      url: "https://www.optimism.io/",
      imageSrc: optimism,
      id: "optimism",
    },
    {
      name: "Internet Computer",
      url: "https://internetcomputer.org/",
      imageSrc: icp,
      id: "icp",
    },
    {
      name: "Lisk",
      url: "https://lisk.com/",
      imageSrc: lisk,
      id: "lisk",
    },
    {
      name: "Celo x Mento Labs x Opera Browser",
      url: "https://www.celo.org/",
      imageSrc: celoMentoOpera,
      id: "celoMentoOpera",
    },

    // {
    //   name: "Metamask",
    //   url: "https://metamask.io/",
    //   imageSrc: metamask,
    //   id: "metamask",
    // },
    // {
    //   name: "Aragon",
    //   url: "https://aragon.org/",
    //   imageSrc: aragon,
    //   id: "aragon",
    // },
    // {
    //   name: "Ethereum Foundation",
    //   url: "https://esp.ethereum.foundation/",
    //   imageSrc: ethereumFoundation,
    //   id: "ethereumFoundation",
    // },
    // {
    //   name: "Aqua Labs",
    //   url: "#",
    //   imageSrc: aqualabs,
    //   id: "aqualabs",
    // },


    // {
    //   name: "Filecoin Foundation",
    //   url: "https://fil.org/",
    //   imageSrc: filecoinFoundation,
    //   id: "filecoinFoundation",
    // },
    // {
    //   name: "Algorand",
    //   url: "https://www.algorand.com/",
    //   imageSrc: algorand,
    //   id: "algorand",
    // },
    // {
    //   name: "Metaversal",
    //   url: "https://www.metaversal.gg/",
    //   imageSrc: Metaversal,
    //   id: "metaversal",
    // },
    // {
    //   name: "Visa",
    //   url: "https://usa.visa.com/",
    //   imageSrc: visa,
    //   id: "visa",
    // },
    // {
    //   name: "Unstoppable Domains",
    //   url: "https://unstoppabledomains.com",
    //   imageSrc: unstoppableDomains,
    //   id: "unstoppableDomains",
    // },
    // {
    //   name: "NEAR",
    //   url: "https://near.or5g/",
    //   imageSrc: near,
    //   id: "near",
    // },

    // {
    //   name: "Encode Club",
    //   url: "https://encode.club/",
    //   imageSrc: encodeClubLogo,
    //   id: "encodeClub",
    // },
    // {
    //   name: "Juicebox",
    //   url: "https://juicebox.money/",
    //   imageSrc: juiceboxLogo,
    //   id: "juicebox",
    // },
    // {
    //   name: "DAOPlanet",
    //   url: "https://daoplanet.org/",
    //   imageSrc: daoPlanetLogo,
    //   id: "daoPlanet",
    // },
    // {
    //   name: "H.E.R DAO",
    //   url: "https://linktr.ee/H.E.R.DAO",
    //   imageSrc: herDAOLogo,
    //   id: "herDao",
    // },

    // {
    //   name: "Africa Blockchain Center",
    //   url: "https://theafricablockchaincenter.com/",
    //   imageSrc: abc,
    //   id: "abc",
    // },
    // {
    //   name: "Africa Blockchain Institute",
    //   url: "https://africablockchain.institute/",
    //   imageSrc: abi,
    //   id: "abi",
    // },
    // {
    //   name: "DoinGud",
    //   url: "https://doingud.com/",
    //   imageSrc: doingud,
    //   id: "doingud",
    // },

    // {
    //   name: "Welook",
    //   url: "https://welook.io//",
    //   imageSrc: welook,
    //   id: "welook",
    // },
    // {
    //   name: "Adalabs",
    //   url: "https://www.adanianlabs.io/",
    //   imageSrc: adalabs,
    //   id: "adalabs",
    // },
  ],
  smPartners: [

    // {
    //   name: "Coinbase",
    //   url: "https://www.coinbase.com/",
    //   imageSrc: coinbase,
    //   id: "coinbase",
    // },
    {
      name: "UTU",
      url: "https://utu.io/",
      imageSrc: utuLogo,
      id: "utu",
    },
    // {
    //   name: "Impact Plus",
    //   url: "https://www.impact-plus.io/",
    //   imageSrc: impactPlus,
    //   id: "impactPlus",
    // },
    {
      name: "Memoi",
      url: "https://www.memoiafrica.com/",
      imageSrc: memoi,
      id: "memoi",
    },
    // {
    //   name: "RegenFoundation",
    //   url: "https://regen.foundation/",
    //   imageSrc: Regen,
    //   id: "regen",
    // },
    {
      name: "ETHAfrica",
      url: "https://ethafrica.org/",
      imageSrc: ethAfrica,
      id: "ethafrica",
    },
    // {
    //   name: "Africoin",
    //   url: "https://africoin.one/",
    //   imageSrc: africoin,
    //   id: "africoin",
    // },
    {
      name: "digipesa",
      url: "https://digipesa.com/",
      imageSrc: digipesa,
      id: "digipesa",
    },
    // {
    //   name: "Jia",
    //   url: "https://www.jia.xyz/",
    //   imageSrc: jia,
    //   id: "jia",
    // },

    // {
    //   name: "Bitlipa",
    //   url: "https://www.bitlipa.com/",
    //   imageSrc: bitlipa,
    //   id: "bitlipa",
    // },
    // {
    //   name: "Nuzo",
    //   url: "https://www.nuzo.co/",
    //   imageSrc: nuzo,
    //   id: "nuzo",
    // },
    // {
    //   name: "Honeycoin",
    //   url: "https://honeycoin.app/",
    //   imageSrc: Honeycoin,
    //   id: "honeycoin",
    // },
    // {
    //   name: "Sharehub",
    //   url: "https://Sharehub.co.ke/",
    //   imageSrc: sharehub_logo,
    //   id: "sharehub",
    // },
    // {
    //   name: "Telos",
    //   url: "https://www.telos.net/",
    //   imageSrc: telos,
    //   id: "telos",
    // },
  ],
  bronzePartners: [

    {
      name: "StarkWare",
      url: "https://starkware.co/",
      imageSrc: starkware,
      id: "starkware",
    },
    {
      name: "Ava Labs",
      url: "https://www.avax.network/",
      imageSrc: avalanche,
      id: "avalanche",
    },
    {
      name: "Base",
      url: "https://base.org/",
      imageSrc: base,
      id: "base",
    },
    {
      name: "Nodo",
      url: "https://nodo.xyz/",
      imageSrc: nodo,
      id: "nodo",
    },

],
  mediaPartners: [

    {
      name: "StreamETH",
      url: "https://streameth.org/",
      imageSrc: streamETH,
      id: "streamETH",
    },
    {
      name: "iTez",
      url: "https://itez.com/",
      imageSrc: itez,
      id: "itez",
    },
    // {
    //   name: "BitKE",
    //   url: "https://bitcoinke.io/",
    //   imageSrc: bitke,
    //   id: "bitke",
    // },
    // {
    //   name: "banklessDAO",
    //   url: "https://www.bankless.community/",
    //   imageSrc: banklessDao,
    //   id: "banklessDao",
    // },
    // {
    //   name: "banklessAfrica",
    //   url: "https://www.banklessafrica.com/",
    //   imageSrc: banklessAfrica,
    //   id: "banklessAfrica",
    // },
    {
      name: "cointelegraph",
      url: "https://cointelegraph.com/",
      imageSrc: cointelegraph,
      id: "cointelegraph",
    },
    // {
    //   name: "kenyanWallstreet",
    //   url: "https://kenyanwallstreet.com/",
    //   imageSrc: kenyanWallstreet,
    //   id: "kenyanWallstreet",
    // },
    // {
    //   name: "DSRPTD",
    //   url:
    //     "https://www.dsrptd.net/post/announcing-ethsafari-the-latest-greatest-eth-event-in-africa",
    //   imageSrc: dsrptd,
    //   id: "dsrptd",
    // },
    // {
    //   name: "Cryptorunner",
    //   url: "https://cryptorunner.com/",
    //   imageSrc: cryptorunners,
    //   id: "cryptorunners",
    // },
    // {
    //   name: "Marco Polo Experience",
    //   url: "marcopoloexperience.com",
    //   imageSrc: marcopolo,
    //   id: "marcoPoloExperience",
    // },
    // {
    //   name: "coinvoice",
    //   url: "https://coinvoice.com/",
    //   imageSrc: coinvoice,
    //   id: "coinvoice",
    // },
    // {
    //   name: "cryptonewsz",
    //   url: "https://cryptonewsz.com/",
    //   imageSrc: cryptonewsz,
    //   id: "cryptonewsz",
    // },
    // {
    //   name: "coinstelegram",
    //   url: "https://coinstelegram.com/",
    //   imageSrc: coinstelegram,
    //   id: "coinstelegram",
    // },
    // {
    //   name: "coinEdition",
    //   url: "https://coinedition.com/",
    //   imageSrc: coinedition,
    //   id: "coinedition",
    // },
    // {
    //   name: "blocktides",
    //   url: "https://blocktidesmedia.com/",
    //   imageSrc: blocktides,
    //   id: "blocktides",
    // },
    // { 
    //   name: "digipesa",
    //   url: "https://digipesa.com/",
    //   imageSrc: digipesa,
    //   id: "digipesa",
    // },
    // {
    //   name: "asiatokenfund",
    //   url: "https://asiatokenfund.com/",
    //   imageSrc: asiatokenfund,
    //   id: "asiatokenfund",
    // },
  ],
}