import hackathonLaunch from '../../images/agenda/gradient/hackathonLaunch.svg';
import hackathonWorkshop from '../../images/agenda/gradient/hackathonWorkshop.svg';
import exhibtionEvents from '../../images/agenda/gradient/exhibtionEvents.svg';
import conference from '../../images/agenda/gradient/conference.svg';
import festival from '../../images/agenda/gradient/festival.svg';
import mailIcon from '../../images/agenda/faq/mail3.svg';
import peopleIcon from '../../images/agenda/faq/people.svg';
import ticketIcon from '../../images/agenda/faq/ticket.svg';
import blocktrain from '../../images/agenda/gradient/blocktrain2.svg';
import ethsafari from '../../images/ethsafari-logo-icon.svg';
import TicketsGuide, {DonationGuide, VolunteerGuide} from "../../../components/TicketsGuide/TicketsGuide";
import React from 'react';
import {ethSafariColors} from "../../theme/theme.js";
import Text from "../../../reusecore/Text/index.js";

const hackathonDetails = (

    <Text TextColor={ethSafariColors.dark} as="span"
          FontFamily={'Poppins'}>Stand a chance to win cash and prizes. The hackathon will begin online from September 1st and pair teams with local and international mentors to take on critical challenges.
        Learn more about our awesome hackathon <a style={{color: ethSafariColors.darkAlt}}  href="#hackathon">here with our amazing partners at Encode Club</a>.</Text>
)
const nairobiAgenda = [
    {
        icon: hackathonLaunch,
        title: 'HACKATHON',
        agendaDate: 'SEPTEMBER 1ST - 22ND (VIRTUAL ONLINE EXPERIENCE)',
        description: hackathonDetails,
        children: hackathonDetails
    },
    {
        icon: hackathonWorkshop,
        title: 'BOOTCAMP ',
        description: 'Get up to speed via our bootcamp between Sept. 19th-21st. You can attend physically or virtually. This will help beginners and the crypto-curious navigate our event and the hackathon better. ',
        agendaDate: 'SEPTEMBER 19TH - 21ST (NAIROBI EXPERIENCE)',
    },

    {
        icon: exhibtionEvents,
        title: 'SIDE EVENTS - EVENTS HOSTED BY LOCAL COMMUNITIES AND PARTNERS',
        agendaDate: '',
        description: 'Great events leave space for others to bring their creativity and energy. Our hosts will run exhibitions and other side events throughout the time in Nairobi.'
    }

];
const kilifiAgenda = [
    {
        icon: conference,
        title: 'WORKSHOPS AND PANEL DISCUSSIONS',
        description: 'The conference will feature a mixture of local, regional, and international speakers. Complete agenda to be published soon.',
        agendaDate: 'SEPTEMBER 22ND - 24TH',
    },
    {
        icon: festival,
        title: 'FESTIVAL',
        agendaDate: 'SEPTEMBER 24TH',
        description: 'We know how to bring proper vibes. Come with us and celebrate builders by the beach.'
    },
];

const blocktrainAgenda = [
    {
        icon: blocktrain,
        title: 'THE SAFARI',
        description: 'Decentralization is a journey and so is ETHSafari. \n' +
            'We will take the Madaraka Express from Nairobi to the Coast through the Kenyan Savannah and Tsavo National Park with views of Mt. Kilimanjaro.\n' +
            'Hacker teams will code while others will attend talks and meetups in other train cars.\n' +
            'We\'ll arrive at the venue in the afternoon to welcome drinks.\n',
        agendaDate: 'SEPTEMBER 22ND',
        icon2: '',
    },
];

const faqAgenda = [
    {
        icon: ethsafari,
        title: 'What is ETHSafari?',
        sectionId: 'faqs',
        description: 'ETHSafari is an event that will be held in Kenya, bringing together people from around world to learn, hack, discuss, and celebrate our decentralized future. Safari is the Swahili word for journey.',
        agendaDate: '',
    },
    {
        icon: ticketIcon,
        title: 'What events will be there?',
        sectionId: 'faqs',
        description: 'ETHSafari will feature an online hackathon, a bootcamp in Nairobi, alongside other side events in the city under the sun.We will have a train journey on the Standard Gauge Railway and the climax, a conference and festival on the Kenyan coast in Kilifi.',
        agendaDate: '',
    },
    {
        icon: peopleIcon,
        title: 'Who should participate?',
        sectionId: 'faqs',
        description: 'Anyone with a passion for all things decentralization, from crypto to web3 and everything in between can participate. We bet that includes you!\n',
        agendaDate: '',
    },
    // {
    //     icon: mailIcon,
    //     title: 'How can I volunteer for ETH Safari?',
    //     sectionId: 'faqs',
    //     description: 'You can join our discord and sign up for our up and coming community board where we reward contributors with SAFARI, our DAO token',
    //     children: <VolunteerGuide/>,
    //     agendaDate: '',
    // },
    // {
    //     icon: mailIcon,
    //     title: 'How can I support to ETH Safari?',
    //     sectionId: 'faqs',
    //     children: <DonationGuide/>,
    //     description: 'You can send ETH to our Juicebox where we use a multisig wallet to manage all funds. Check it out in the section below. Contributing to ETH Safari by buying some SAFARI means we have more opportunity to engage and support the community and as they support us ',
    //     agendaDate: '',
    // },
    {
        icon: mailIcon,
        title: 'Where can I send my questions?',
        sectionId: 'faqs',
        description: 'You can message us on Telegram by looking for t.me/ethsafari or mail us at info@ethsafari.xyz',
        agendaDate: '',
    },

];

export default {
    guideAgenda: nairobiAgenda,
    blocktrainAgenda,
    kilifiAgenda,
    faqAgenda
};