const handleBuyTicketClick = (e) => {
    // Lets track that custom click
    const data =  {
        // string - required - The object that was interacted with (e.g.video)
        category: "Buy Ticket Form Opened",
        // string - required - Type of interaction (e.g. 'play')
        action: "Buy Ticket",
        // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
        label: "ETHSafari Home Page Buy Ticket",
        // number - optional - Numeric value associated with the event. (e.g. A product ID)
        value: 1,
    };
    typeof window !== "undefined" && window.gtag("Buy Ticket Form Opened", "click", { ...data })
}

export function titleCase(str) {
    if(!str) {
        return ''
    }
    str = str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
}
export {handleBuyTicketClick}