import styled from "styled-components";
import BannerWrapper from "../BannerTwo/banner-two.style";

const UserMapWrapper = styled(BannerWrapper)`
  padding: 85px 0;
  background: rgba(255,255,255,1);

  .usermap-wrapper {
    img {
      margin-top: 37px;
    }
  }
  
  .text-description {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .text-name {
    text-align: center;
    justify-content: center;
    line-height: 3rem;
  }
  
  .hackathon-prize-container {
    margin-bottom: 4rem;
  }
  
  iframe {
    min-height: 40rem;
  }
  
  @media only screen and (max-width: 912px) {
    .heading{
      font-size: 34px;
    }
  }
  @media only screen and (max-width: 568px) {
    .heading{
      font-size: 30px;
    }
  }
  @media only screen and (max-width: 480px) {
    padding: 20px 0;
  }
`;

export default UserMapWrapper;
