import React, {useContext, useEffect, useRef} from "react"
import { Link } from "gatsby"

import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Badge from "reusecore/Badge"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import useConferenceAnimation from '../../utils/hooks/useConferenceAnimation'
import BannerImage from "assets/images/2023/FPG_7_peepsImg.png"

import particleTopLeft from "assets/images/agenda/blocktrain/birdBlockTrain.svg"
import particleUnderLogo from "assets/images/conference/gold@2x.png"
import particleTopRight from "assets/images/agenda/gradient/bird6.svg"
import particleBottomLeft from "assets/images/conference/standard@2x.png"
import particleBottomRight from "assets/images/conference/lite@2x.png"
import tracksBg from "assets/images/conference/tracks-bg.png"
import conferenceBg from "assets/images/conference/conference-bg-print5.png"
import tracksBgStrip from "assets/images/conference/gradient-overlay.png"
import { gsap } from "gsap";
import BannerWrapper, {CustomBannerWrapper, TracksDetails} from "./banner.style"
import CitiesSlider, {slides} from "../../components/Slider/Slider.js";
import {ethSafariColors} from "../../assets/theme/theme.js";
import {PopupButton} from "@typeform/embed-react";
import {handleBuyTicketClick, titleCase} from "../../utils/forms.utils.js";
import styled from "styled-components";

import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import {Card} from "../../reusecore/Card/index.js";
import ConferenceCard from "../../reusecore/ConferenceCard/ConferenceCard.js";
import Button from "../../reusecore/Button/index.js";
import {ConfAgendaWrapper} from "../About/about.style.js";
import {SectionBackground, SectionTitle} from "../../reusecore/SectionTitle/index.js";
import safariGem from "../../assets/images/ether-diamond.gif";
import Image from "../../reusecore/Image"

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.defaults({ toggleActions: "play none reverse pause" });
const Banner = () => {

  return (
    <BannerWrapper>
      <Image
        src={particleTopLeft}
        className="section__particle top-left"
        alt="cryptik particles"
      />
      <Image
        src={particleUnderLogo}
        className="section__particle two"
        alt="cryptik particles"
      />
      <Image
        src={particleTopRight}
        className="section__particle top-right"
        alt="cryptik particles"
      />
      <Image
        src={particleBottomLeft}
        className="section__particle bottom-left"
        alt="cryptik particles"
      />
      <Image
        src={particleBottomRight}
        className="section__particle bottom-right"
        alt="cryptik particles"
      />
      <Container className={'guide-container'}>
        <Row>
          <Col className="lg-8 xs-12">
            <Box className="banner-content">
              {/*<Badge className="offer-text">*/}
              {/*  14 days, a huge of free trial*/}
              {/*</Badge>*/}
              <Heading as="h1">
                DeFi <br /> The crypto market and the future
              </Heading>
              <Text>
                The highly the not having with lively. Our up with ran go her{" "}
                <br />
                it gloomy the back, though however projected not for six with
                then, <br /> trusted as concepts belt.
              </Text>
              <Box className="banner-btn">
                <Link to="#" className="btn btn-fill">
                  {" "}
                  Get in touch{" "}
                </Link>
                <Link to="#" className="btn">
                  {" "}
                  white paper{" "}
                </Link>
              </Box>
            </Box>
          </Col>
        </Row>
        <Image
          src={BannerImage}
          className="banner__thumb"
          alt="cryptik banner thumb"
        />
      </Container>
    </BannerWrapper>
  )
}
const ConferenceBanner = () => {
    const { el, dailyOverview } = useConferenceAnimation()
  return (
      <div id={'agenda'} className={'conference-banner'} style={{overflow: 'visible'}} ref={el}>
          <CustomBannerWrapper className={`conference-container`} bgImg={conferenceBg}>
              <img
                  src={particleTopLeft}
                  className="section__particle top-left left-animations"
                  alt="ethsafari particles"
              />
              <img
                  src={particleUnderLogo}
                  className="section__particle two left-animations"
                  alt="ethsafari particles"
              />
              <img
                  src={particleTopRight}
                  className="section__particle top-right right-animations"
                  alt="ethsafari particles"
              />
              <img
                  src={particleBottomLeft}
                  className="section__particle bottom-left left-animations"
                  alt="ethsafari particles"
              />
              <img
                  src={particleBottomRight}
                  className="section__particle bottom-right right-animations"
                  alt="ethsafari particles"
              />
              <Container>
                  <Row>
                      <Col className="lg-9 xs-12">
                          <Box className="banner-content">
                              {/*<Badge className="offer-text">*/}
                              {/*  14 days, a huge of free trial*/}
                              {/*</Badge>*/}
                              <Heading UpperCase={true} FontFamily={'Poppins-Bold'} HeadingColor={ethSafariColors.whiteTone} as="h1">
                                  THE GUIDE <br />
                              </Heading>
                              <Text FontFamily={'Poppins-Medium'} style={{fontSize: '2rem',color: ethSafariColors.whiteTone}}>
                                  Join us on our journey as we learn, discuss and build a better decentralized present and future from the heart of East Africa.<br/>
                              </Text>
                              <Text style={{color: ethSafariColors.whiteTone}} FontFamily={'Poppins-SemiBold'}>Hosted at <a
                                  href="https://beneaththebaobabs.com" style={{color: ethSafariColors.lightAlt}} target={'_blank'}>Beneath The Baobabs, Kilifi</a> </Text>
                              {/*<Text className={'conference-banner-text'} FontFamily={'Poppins-SemiBold'} style={{color: ethSafariColors.whiteTone}}>*/}
                              {/*    The conference is happening from September 22nd - 24th{" "}. It comes after the Nairobi event which ends on Wednesday 21st September.*/}
                              {/*    <br />*/}
                              {/*    We leave from Nairobi for Kilifi early morning (7am) on September 22nd to arrive in Kilifi in the afternoon. Journey with us as we bring in more web3 collaboration and build in the blockchain ecosystem.*/}
                              {/*    <br /> #KaribuSafari*/}
                              {/*</Text>*/}
                              <ConfAgendaWrapper>
                                  <Container>
                                      <Row><Heading FontFamily={'Poppins-Bold'} as={'h1'}> CONFERENCE PROGRAM OVERVIEW</Heading></Row>
                                      <Col className="md-12 sm-12">
                                          <Box className="about-right-content">
                                              <SectionTitle>
                                                  <SectionBackground>
                                                      <Text FontFamily={`Poppins-SemiBold`}>
                                                        {/* ETHSafari is the blockchain tech event of the season! <br/> */}
                                                        From September <b>1st</b> to the <b>20th</b>, join our virtual Bootcathon for decentralized EVM innovation, learning and building. <br/>
                                                        Then, from September <b>21st</b> to the <b>24th</b>, experience the ETHSafari Conference with workshops, panels, and keynotes amidst the palm-fringed paradise of Kilifi, Kenya.
                                                        Unleash your creativity and explore the future of decentralization. 
                                                        <br/> #Karibu Safari!🦓
                                                      </Text>
                                                      <Text FontFamily={`Poppins`} as={'span'}>
                                                          Quick summary <Image style={{ width: '1.2rem'}} src={safariGem} alt="Safari Gem"/>
                                                      </Text>
                                                      <Box className="about-list">
                                                          {dailyOverview.map((overview) => {
                                                              return (
                                                                  <>
                                                                      <Text FontSize={'0.4rem'} FontFamily={`Poppins`} as={'span'}>
                                                                           <b style={{fontFamily: 'Poppins-SemiBold'}}>{overview.date}</b> - {overview.title}
                                                                      </Text>
                                                                  </>
                                                                  )
                                                          })}
                                                      </Box>
                                                  </SectionBackground>


                                              </SectionTitle>

                                              {/*<Box className="about-list">*/}
                                              {/*    <Text FontFamily={`Poppins`} as={'span'}>*/}
                                              {/*        We leave from Nairobi for Kilifi early morning (7am) on September 22nd to arrive in Kilifi in the afternoon. Journey with us as we bring in more web3 collaboration and build in the blockchain ecosystem.*/}
                                              {/*        <br /> #KaribuSafari*/}
                                              {/*    </Text>*/}
                                              {/*    <Text FontFamily={`Poppins`} as={'span'}>*/}
                                              {/*        We leave from Nairobi for Kilifi early morning (7am) on September 22nd to arrive in Kilifi in the afternoon.*/}
                                              {/*    </Text>*/}
                                              {/*</Box>*/}

                                              {/*<Box className="about-btn-wrapper">*/}
                                              {/*  <Link to="#" className="btn btn-fill">*/}
                                              {/*    {" "}*/}
                                              {/*    <FaGooglePlay /> Google Play*/}
                                              {/*  </Link>*/}
                                              {/*  <Link to="#" className="btn">*/}
                                              {/*    <FaApple /> Apple Store*/}
                                              {/*  </Link>*/}
                                              {/*</Box>*/}
                                          </Box>
                                      </Col>
                                  </Container>
                              </ConfAgendaWrapper>
                              <Box style={{display: 'flex', justifyContent: 'space-between'}} >
                                  <PopupButton as={Button} altBtn={true} btnBg={ethSafariColors.darkAlt} id="ZzRSKc9X" onClick={handleBuyTicketClick}>
                                          {/* <FaGithub /> BUY  */}
                                          <Text FontFamily={'Poppins-SemiBold'} className="btn-text" as="span">Get Your Ticket!</Text>
                                  </PopupButton>
                                  <Link to="/speakers">
                                      <Button altBtn={true}>
                                          <Text FontFamily={'Poppins-SemiBold'} className="btn-text" as="span">Our Speakers</Text>
                                      </Button>
                                  </Link>
                              </Box>
                          </Box>
                      </Col>
                  </Row>
                  <img
                      src={BannerImage}
                      className="banner__thumb right-animations"
                      alt="ethsafari banner thumb"
                  />
              </Container>

          </CustomBannerWrapper>

          {/*<TracksDetails>*/}
          {/*    <Container>*/}
          {/*        <Row className={'tracks-container'}>*/}
          {/*            <ConferenceCard/>*/}
          {/*            <ConferenceCard/>*/}
          {/*            <ConferenceCard/>*/}
          {/*            <ConferenceCard/>*/}
          {/*        </Row>*/}
          {/*    </Container>*/}
          {/*</TracksDetails>*/}
      </div>
  )
}

const ConferenceAgenda = () => {
    return (<Row  bgImg={tracksBgStrip} className={'fluid'} style={{backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
        <img
            src={particleTopLeft}
            className="section__particle top-left"
            alt="ethsafari particles"
        />
        <img
            src={particleUnderLogo}
            className="section__particle two"
            alt="ethsafari particles"
        />
        <img
            src={particleTopRight}
            className="section__particle top-right"
            alt="ethsafari particles"
        />
        <img
            src={particleBottomLeft}
            className="section__particle bottom-left"
            alt="ethsafari particles"
        />
        <img
            src={particleBottomRight}
            className="section__particle bottom-right"
            alt="ethsafari particles"
        />
        <Row style={{width: '100%', display: 'flex', flexDirection: 'column'}} >
            <Heading FontFamily={'Poppins-ExtraBold'} UpperCase={true} FontSize={'4rem'} style={{textAlign: 'center'}} HeadingColor={ethSafariColors.darkAlt}>JOIN THE SAFARI </Heading>
            <TracksDetails>
                <Container>
                    <Row className={'tracks-container'}>
                        <Col className="lg-3 xs-12">
                            <div className="card">
                                <Image
                                    src="https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
                                    alt="Image"/>
                                <div className="content">
                                    <h1>DeFi</h1>
                                    <h3>Lorem ipsum sit amet dolor.</h3>
                                    <button>More</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </TracksDetails>
        </Row>
    </Row>)
}

export {TracksDetails}
export {ConferenceBanner}
export default Banner
