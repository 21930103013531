import serviceIcon1 from "assets/images/service/service-icon-1.svg";
import serviceIcon2 from "assets/images/service/service-icon-2.svg";
import serviceIcon3 from "assets/images/service/service-icon-3.svg";
import serviceIcon4 from "assets/images/service/service-icon-4.svg";

export default {
  services: [
    {
      icon: serviceIcon1,
      title: "Decentralized",
      body: "Find your local Crypto King or Crypto Queen",
    },
    {
      icon: serviceIcon2,
      title: "Crypto education",
      body: "Help to get started, and advice on HODL strategies",
    },
    {
      icon: serviceIcon3,
      title: "Self-custody",
      body: "Fully owner and manager of your crypto",
    },
    {
      icon: serviceIcon4,
      title: "Mobile Payments",
      body: "Enabling Peer 2 Peer mobile payments",
    },
  ],
};
