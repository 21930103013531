import {useEffect, useState} from "react";


export default function useFetchSchedule(){
    const [sessions, setSessions] = useState([])
    useEffect(() => {
        const fetchSched = async () => {
            const results = await fetch('https://ethsafari2023.sched.com/api/session/list?api_key=f2d86b75cba70b0415d1ad90e7aa8025&format=json').then(res => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.json()
            })
            setSessions(results)
        }
        fetchSched()

    }, [])
    useEffect(() => {
        console.log(sessions)
    })
    return { sessions }
}