import FaqSectionWrapper from "../Faq/faq.style.js";
import styled from "styled-components";
import {ethSafariColors} from "../../assets/theme/theme.js";


const  SchedProgramWrapper = styled(FaqSectionWrapper)`
  
  .link-container {
    padding:0.5rem;
    border-radius: 3rem;
    border: 2px ${(ethSafariColors.dark)} ;
    color: ${(ethSafariColors.whiteTone2)};
    transition: all ease-in-out 0.3s;
    &:hover, &:focus, &:active{
      color: ${ethSafariColors.dark};
    }
  }
  .accordion__header > div {
    width: 100%;
  }
  .accordion-title-text, .accordion-title-location {
    margin: 0.25rem;
    padding: 0.25rem;
  }
  .faq-content {
    position: relative;
    margin-top: 95px;
    ul.react-tabs__tab-list {
      /*      position: absolute;
            right: -106%;
            top: -160px;*/
      border: none;
      font-family: 'Poppins-ExtraBold';

      li {
        padding: 14px 33px;
        border: none;
        border-radius: 3rem;
        color: ${props => props.theme.ethSafariColors.whiteTone};
        font-family: 'Poppins-ExtraBold';

        &.react-tabs__tab--selected {
          background: ${props => props.theme.ethSafariColors.dark};
          color:  ${props => props.theme.ethSafariColors.whiteTone};
          font-family: 'Poppins-ExtraBold';
        }
      }
    }
  }

  .accordion__item {
    background: ${props => props.theme.ethSafariColors.dark};
    color: ${props => props.theme.ethSafariColors.lightAlt};
    border: none;
    border-radius: 3rem;
    padding: 30px;

    .heading {
      font-size: 17px;
      font-weight: 300;
      margin: 0;
    }

    > div {
      [aria-expanded="true"] {
        .heading {
          margin-bottom: 10px;
        }
      }
    }
  }
  
  
`;

export default SchedProgramWrapper;