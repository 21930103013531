import { useEffect, useRef } from 'react';
import gsap from 'gsap';

const useLandingAnimation = () => {
  const el = useRef();
  const q = gsap.utils.selector(el);
  const tl = useRef();

  useEffect(() => {

    tl.current = gsap.timeline({ scrollTrigger: { trigger: "#bannertwo", scrub: true, endTrigger: "#ZzRSKc9X", start: "-=4rem center", end: "top center"} })
      .fromTo(".section__stripes", { autoAlpha: 0, x: '-=10rem' }, { x: '+=10rem', autoAlpha: 1 });


  }, []);

  return { el, q, tl};
};

export default useLandingAnimation;
