import styled from "styled-components";


const BannerWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding: 278px 0 150px 0;

  .banner__thumb{
    position: absolute;
    right: -4%;
    top: -12%;
    width: 40%;
  }
  
  .section__particle {
    position: absolute;
    &.top-left {
      left: 49px;
      top: 275px;
      height: 150px;
      z-index: 3;
    }
    &.top-right {
      top: 65px;
      right: 28px;
      height: 74px;
    }
    &.bottom-left {
      bottom: 0px;
      left: 15px;
      height: 150px;
    }
    &.two {
      top: 6px;
      left: 140px;
      height: 87px;
    }
    &.bottom-right {
      right: 49px;
      bottom: 129px;
      height: 200px;
    }
  }

  .banner-content {
    .offer-text {
      color: #ffffff;
      padding: 12px 30px;
      border-radius: 30px;
      font-size: 14px;
      margin-bottom: 33px;
      font-weight: 400;
      background: rgb(35, 42, 213);
      background: linear-gradient(
        87deg,
        rgba(35, 42, 213, 1) 45%,
        rgba(194, 56, 231, 1) 100%
      );
    }

    h1 {
      margin: 0 0 30px 0;
      font-size: 45px;
      line-height: 65px;
    }

    p {
      margin-bottom: 60px;
      color: #BEABDF;
      font-size: 18px;
      line-height: 28px;
    }

    .banner-btn {
      a {
        font-size: 16px;
        padding: 18px 35px;
        border: 1px solid #ffffff;
        color: #ffffff;
        transition: all 0.3s ease-in;
        &:hover{
          background: #ffffff;
          color: ${(props) =>
          props.theme.linkColor ? props.theme.linkColor : "#1F2AD5"};
        } 
      }

      .btn-fill {
        background: #ffffff;
        color: ${(props) =>
        props.theme.linkColor ? props.theme.linkColor : "#1F2AD5"};
      }

      a + a {
        margin-left: 20px;
      }
    }
  }


  @media only screen and (max-width: 912px) { 
    padding: 215px 0 150px 0;
    .banner__thumb{
      display: none;
    }
    .banner-content {
      h1{
        font-size: 40px;
        line-height: 60px;
      }
    }
  }
  @media only screen and (max-width: 768px) { 
    padding: 180px 0 120px 0;
    .banner-content {
      h1{
        font-size: 35px;
        line-height: 55px;
      }
    }
  }
  @media only screen and (max-width: 480px) { 
    .banner-content {
      h1{
        font-size: 30px;
        line-height: 50px;
        br{
          display: none;
        }
      }
      .text{
        br{
          display: none;
        }
      }
    }

    .banner__thumb {
      display: none;
    }
    .section__particle{
      &.bottom-right{
        display: none;
      }
    }
  }
  @media only screen and (max-width: 375px) { 
    .banner-content {
      h1{
        font-size: 25px;
        line-height: 40px;
      }
      .banner-btn{
        .btn{
          width: 155px;
          padding: 18px 5px;
        }
      }
    }
  }
  @media only screen and (max-width: 320px) { 
    .banner-content {
      h1{
        font-size: 22px;
        line-height: 35px;
      }
      .banner-btn{
        .btn{
          width: 170px;
          padding: 18px 5px;
          display: block;
        }
        a+a{
          margin: 15px 0 0 0;
        }
      }
    }
  } 
`;

const CustomBannerWrapper = styled(BannerWrapper)`
  overflow: hidden;

  .banner-content p {
    line-height: 3rem;
  }
background: ${props => props.bgImg ? `url(${props.bgImg})` : "transparent"};
  background-repeat: no-repeat;
  background-size: cover;
  
  .conference-banner-text {
    background-color: rgba(0,0,0,0.5);
    padding: 3rem 2rem;
    border-radius: 2rem
  }

  .banner-btn{
    display: flex;
    padding: 0 0.5rem;
    justify-content: space-between;
  }
`;


const TracksDetails = styled.div`
  justify-content: center;
  display: flex;
    *{
      margin: 0;
      padding: 0;
      width: 100%;
      box-sizing: border-box;
      transition: all 0.5s linear;
    }

    .tracks-container{
      display: flex;
      //background: linear-gradient(0deg, #fff, #E6E6E6);
      align-items: center;
      justify-content: center;
      min-height: 30rem;
      background: ${props => props.bgImg ? `url(${props.bgImg})` : "transparent"};
    }

    .tracks-container::before{
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      content: "";
      position: absolute;
      //background: linear-gradient(0deg, #EA0000, #FF0078);
      //clip-path: circle(30% at left 90%);
    }

    p{
      font-family: "Josefin Sans", Arial;
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 14px;
      margin: 0.7rem 1rem;
    }

    p a{
      text-decoration: none;
      color: black;
    }

    p a:hover{
      color: red;
    }

    .tracks-container::after{
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      content: "";
      position: absolute;
    }

    .card, img{
      width: 250px;
      border-radius: 15px;
    }

    .card{
      overflow: hidden;
      margin: 1rem;
      height: 375px;
      box-shadow: 20px 20px 60px #9d9d9d, -20px -20px 60px #ffffff;
    }

    .card:hover{
      transform: translateY(-1%);

    }

    .card:hover .content{
      top: -55%;
      opacity: 1;
    }

    .content{
      padding: 1rem 0.7rem;
      position: relative;
      opacity: 0;
      font-family: "Josefin Sans", Arial;
      display: flex;
      box-shadow: none;
      backdrop-filter: blur(10px);
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-top: 0;
      text-align: center;
    }

    .content h1{
      font-size: 23px;
      color: red;
      margin: 0.7rem 0;
      margin-top: 2rem;
    }

    .content h3{
      color: #fff;
      font-size: 15px;
      font-weight: 300;
      letter-spacing: 2px;
      line-height: 1.6;
    }

    button{
      margin: 1rem;
      border: 2px solid red;
      background: red;
      padding: 0.7rem 1rem;
      text-transform: uppercase;
      font-weight: 600;
      color: white;
      box-shadow: 0 7px 12px rgba(0, 0, 0, 0.1);
      letter-spacing: 2px;
      cursor: pointer;
      border-radius: 20px;
    }

    button:hover{
      background: #EF0000;
      box-shadow: 0 7px 12px rgba(0, 0, 0, 0.1);
    }
`;
export {CustomBannerWrapper, TracksDetails};

export default BannerWrapper;
