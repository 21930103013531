import Box from "../../reusecore/Box/index.js";
import Text from "../../reusecore/Text/index.js";
import { ethSafariColors } from "../../assets/theme/theme.js";
import styled from "styled-components";
import FaqSectionWrapper from "../../sections/Faq/faq.style.js";
import React from 'react';
import CoinFundWrapper from "../../sections/CoinFund/coinFund.style.js";


const GeneralGuideWrapper = styled(CoinFundWrapper)`
  background: transparent;
  
  font-family: Poppins;

  &::before {
    display: none;
  }

  p {
    font-size: 0.95rem;
    font-family: Poppins;
    color: ${ethSafariColors.whiteTone};
  }

  .text-link {
    color: ${ethSafariColors.lightAlt};
  }
`;



function AccomodationGuide(props) {
    return (
        <GeneralGuideWrapper>
            <Box>
                <div>
                    <p>You can check out places to stay in Kilifi on <a className="text-link" target="_blank" href="https://www.airbnb.com/">airbnb</a> or <a className="text-link" target="_blank" href="https://www.booking.com/">booking.com</a>.</p>
                </div>
            </Box>
        </GeneralGuideWrapper>
    )
}

function NairobiGuide(props) {
    return (
        <GeneralGuideWrapper>
            <Box>
                <div>
                    <p>In Nairobi, ground transportation can be accessed through our official ride hailing partners <a className="text-link" target="_blank" href="https://maramoja.co.ke/">MaraMoja</a>, or any of the popular options like Uber and Bolt. </p>
                </div>
            </Box>
        </GeneralGuideWrapper>
    )
}
function BlocktrainGuide(props) {
    return (
        <GeneralGuideWrapper>
            <Box>
                <div>
                    <p>After buying an event ticket you will receive an invite to signup for the BlockTrain.</p>
                    {/* <p>Speakers and Sponsor attendees can provide the necessary details for the BlockTrain through this <a className="text-link" target="_blank" href="https://maramoja.co.ke/">form</a>.</p> */}
                    
                </div>
            </Box>
        </GeneralGuideWrapper>
    )
}

export { AccomodationGuide,NairobiGuide,BlocktrainGuide };

export default function TravelGuide(props) {
    return (
        <GeneralGuideWrapper>
            <Box>
                <div>
                    <div>
                        <p>You can apply for your visa <a className="text-link" target="_blank" href="https://evisa.go.ke/evisa.html">here</a>. Register with your country of origin’s consular department. East African citizens do not need a visa. You can confirm whether you are applicable for a visa <a className="text-link" target="_blank" href="https://immigration.ecitizen.go.ke/index.php?id=6">here</a>.
                        </p>
                        <p>Kenya Has A Ban On Single-Use Plastics, and these will be surrendered upon entry.</p>
                    </div>
                    
                </div>
            </Box>
        </GeneralGuideWrapper>

    )
}
