import React from 'react';
import FaCustomIcon from "./FaCustomIcon.js";
import nairobiIcon from "../../images/agenda/gradient/NairobiSilo.svg";

const FaNairobiSilo = ({className, children}) => {
    return (
        <FaCustomIcon className={className}>
                <img src={nairobiIcon}/>
        </FaCustomIcon>
    );
}

export default FaNairobiSilo;
