import React, { useEffect, useState } from "react"
import { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Navigation from "sections/Navigation"
import Footer from "sections/Footer"

import theme from "assets/theme/theme"
import GlobalStyle from "assets/theme"

import ReactGA from "react-ga"
import { CustomAgenda } from "../sections/Agenda/index.js"
import FaNairobiSilo from "../assets/theme/CustomIcons/FaNairobiSilo.js"
import agendaSamples from "../assets/data/agenda/agendaSamples.js"
import KilifiIcon from "../assets/theme/CustomIcons/KilifiLocationIcon.js"
import BlocktrainAnimation from "../assets/theme/CustomIcons/blocktrainAnimations/BlocktrainAnimations"
import Faq from "../sections/Faq/index.js"
import Stack from "../sections/Stack/index.js"
import BannerTwo from "../sections/BannerTwo/index.js"
import CoinFund from "../sections/CoinFund/index.js"
import { CustomService } from "../sections/Service/index.js"
import nairobiIcon from "../assets/images/agenda/gradient/NairobiSilo.svg"
import skyline from "../assets/images/agenda/gradient/skyline.svg"
import FaCustomIcon from "../assets/theme/CustomIcons/FaCustomIcon.js"
import kilifiLocationIcon from "../assets/theme/CustomIcons/KilifiLocationIcon.js"
import UserMap from "../sections/UserMap/index.js"
import VideoEmbed from "../sections/VideoEmbed/index.js"
import { TRACKING_ID } from "../assets/data/config.js"
import GA4React from "ga-4-react"
import Banner, { ConferenceBanner } from "../sections/Banner/index.js"
import About, { ConferenceProgram } from "../sections/About/index.js"
import SchedProgram from "../sections/SchedProgram/index.js"
import useFetchSchedule from "../utils/hooks/useFetchSchedule.js"
import { hiddenVenues } from "../utils/constants/index.js"
import moment from "moment-timezone"
import { titleCase } from "../utils/forms.utils.js"
import Subscribe from "../sections/Subscribe"
import { ToastProvider } from "react-toast-notifications"
// ReactGA.initialize(TRACKING_ID);
// ReactGA.pageview(window.location.pathname + window.location.search);

/*
TODO make lots of ui icon updates for all screen sizes
DONE update Faq section
DONE add link to juicebox section
DONE fix the header and subtitle alignment on agenda items
TODO add fix build fonts
TODO add partners section
TODO add guide section icons
TODO add blocktrain icons
TODO add destination section icons
TODO add faqs section icons
 */

/*
TODO make lots of ui animation updates for all screen sizes
TODO add guide section animations
TODO add blocktrain section animations
TODO add destination section animations
TODO add faqs section animations
 */

// TODO test out ticket flow again

// TODO update delegates community notion board

const IndexPage = () => {
  const agendaKeys = Object.keys(agendaSamples)
  const [sortedSessions, setSortedSessions] = useState([])
  const nairobiIcon = <FaNairobiSilo className="agenda-section-title-img" />
  const kilifiIcon = (
    <FaCustomIcon className="agenda-section-title-img">
      <img src={skyline} />
    </FaCustomIcon>
  )

  const ga4react = new GA4React(TRACKING_ID)
  useEffect(() => {
    ga4react
      .initialize()
      .then(ga4 => {
        ga4.pageview("Home")
        ga4.gtag("event", "pageview", "home")
      })
      .catch()
  }, [])

  const agendaSections = [
    {
      id: "guide",
      title: "THE GUIDE",
      safariLocation: "NAIROBI",
      locationIcon: nairobiIcon,
    },
    {
      id: "blocktrain",
      title: "THE BLOCKTRAIN",
      bgAgenda: "#23B7C5",
      agendaTitleColor: "#FDD2FD",
      safariLocation: "",
      locationIcon: "",
    },
    {
      id: "destination",
      title: "THE CONFERENCE",
      safariLocation: "KILIFI",
      locationIcon: kilifiIcon,
    },
    // {id: 'faqs', title: 'FAQ', safariLocation: '', locationIcon: '', items: faqsAgenda},
  ]
  const sections = agendaKeys.map(agendaSection => {
    return <CustomService section={agendaSamples[agendaSection]} />
  })
  const { sessions } = useFetchSchedule()
  useEffect(() => {
    setSortedSessions(
      sessions
        .filter(sesh => {
          if (!hiddenVenues.includes(sesh.venue?.toLowerCase())) {
            return sesh
          }
        })
        .filter(sesh => {
          const time = moment.tz(
            sesh.event_start,
            "YYYY-MM-DD HH:mm:ss",
            "Africa/Nairobi"
          )
          if (time.isAfter("2023-09-17")) {
            return sesh
          }
        })
        .sort((a, b) => {
          const timeA = moment.tz(
            a.event_start,
            "YYYY-MM-DD HH:mm:ss",
            "Africa/Nairobi"
          )
          const timeB = moment.tz(
            b.event_start,
            "YYYY-MM-DD HH:mm:ss",
            "Africa/Nairobi"
          )
          if (timeA.isAfter(timeB)) {
            return 1
          }
          if (timeB.isAfter(timeA)) {
            return -1
          }
          return 0
        })
    )
  }, [sessions])

  useEffect(() => {
    const topics = new Set()
    sortedSessions.forEach(session => {
      topics.add(session.event_type)
    })
    // TODO Provide sessions sorted by topics instead of time and date?
    const topicsArray = Array.from(topics)
    const topicsSortedList = topicsArray.map(topic => {
      return {
        title: titleCase(topic),
        items: sortedSessions.filter(session => session.event_type === topic),
      }
    })
  }, [sortedSessions])
  const sampleAgendaUpdated = (
    <div className={"sample-agenda-update"}>
      {agendaSections.map((agenda, index) => {
        if (agenda.id === "blocktrain") {
          return (
            <CustomAgenda
              title={agenda.title}
              safariLocation={agenda.safariLocation}
              locationIcon={agenda.locationIcon}
              items={sections[1]}
              bgAgenda={agenda.bgAgenda}
              agendaTitleColor={agenda.agendaTitleColor}
            >
              {/*<BlocktrainAnimation/>*/}
            </CustomAgenda>
          )
        }
        return (
          <CustomAgenda
            title={agenda.title}
            safariLocation={agenda.safariLocation}
            locationIcon={agenda.locationIcon}
            items={sections[index]}
            bgAgenda={agenda.bgAgenda}
            agendaTitleColor={agenda.agendaTitleColor}
          />
        )
      })}
    </div>
  )
  return (
    <ToastProvider
      autoDismissTimeout={5000}
      autoDismiss={true}
      placement="bottom-center"
    >
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Layout>
          <SEO title="Home" />
          <Navigation />
          <BannerTwo />
          <VideoEmbed />
          <Subscribe />
          {/* The Guide */}
          {/* <ConferenceBanner /> */}
          {/* The Conference Overview and Themes */}
          {/* <ConferenceProgram /> */}
          {/* Agenda from Sched */}
          {/* <SchedProgram sessions={sortedSessions} /> */}
          {/* {sampleAgendaUpdated} */}
          <Stack />
          <UserMap />
          <Faq />
          <CoinFund />
          <Footer />
        </Layout>
      </ThemeProvider>
    </ToastProvider>
  )
}

export default IndexPage
