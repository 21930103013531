import agendaSamples from "../agenda/agendaSamples.js"
import React from "react"
import Box from "../../../reusecore/Box/index.js"
import Text from "../../../reusecore/Text/index.js"
import { ethSafariColors } from "../../theme/theme.js"
import TicketsGuide from "../../../components/TicketsGuide/TicketsGuide.js"
import TravelGuide, {
  AccomodationGuide,
  NairobiGuide,
  BlocktrainGuide,
} from "../../../components/TravelGuide/travelGuide"
// import AccomodationGuide  from "../../../components/TravelGuide/travelGuide";

const customFaqItems = agendaSamples.faqAgenda.map(
  ({ description, ...agendaItem }) => ({
    ...agendaItem,
    body: description,
  })
)
export default {
  faq: [
    {
      name: "General",
      items: [...customFaqItems],
    },
    // {
    //   name: "Tickets",
    //   items: [
    //     {
    //       title: "How Can I Get An ETHSafari Ticket?",
    //       body: "ETHSafari tickets are available now!",
    //       children: <TicketsGuide />
    //     },
    //   ]
    // },
    {
      name: "Travel",
      items: [
        {
          title: "What I Need To Come To Kenya?",
          body: "You need a valid passport and visa to visit Kenya.",
        },
        {
          title: "How do I get a Visa?",

          children: <TravelGuide />,
        },
        {
          title: "Where can I book accommodation? ",
          children: <AccomodationGuide />,
        },
        {
          title: "How do I get around in Nairobi?  ",
          children: <NairobiGuide />,
        },
        {
          title: "When do I get my BlockTrain Ticket?   ",
          children: <BlocktrainGuide />,
        },
      ],
    },
  ],
}
