import styled from "styled-components";
import footerBg from "assets/images/footer-bg.png";

const KilifiIconWrapper = styled.div`
  .kilifi-icon-container {
    
  }
  .sun-img {
    position: absolute;
    width: 7rem;
    top: -8rem;
    padding-top: 1rem;
    right: -1.8rem;
  }
  
  .sky-container {
    width: 12rem; 
    right: 0.625rem;
    top: -9.375rem;
  }
  
  .cloud-img {
    position: absolute;
    left: 5.625rem;
    top: -4rem;
  }
  .beach-container{
    min-height: 8.75rem;
  }
  .palm-trees {
    position: absolute;
    width: 9.7rem;
  }

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen
  and (min-device-width : 320px)
  and (max-device-width : 767px) {
    max-width: 5rem;
    /* Styles */
    .cloud-img {
      position: absolute;
      left: 5.625rem;
      top: 2rem;
      width: 4rem;
    }
    
    .sun-img{
      position: absolute;
      width: 6rem;
      top: -1rem;
      padding-top: 1rem;
      right: -4.8rem;
    }

    .palm-trees {
      position: absolute;
      width: 4.6rem;
      bottom: 1rem;
    }
  }

  /* Smartphones (landscape) ----------- */
  @media only screen
  and (min-width : 321px) {
    /* Styles */
  }

  /* Smartphones (portrait) ----------- */
  @media only screen
  and (max-width : 320px) {
    /* Styles */
  }

  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) {
    /* Styles */
    .sky-container {
      width: 12rem;
      right: 0.625rem;
      top: -9.375rem;
    }

    .sun-img {
      width: 7rem;
      top: -5rem;
      padding-top: 1rem;
      right: -1.87rem;
    }
    .cloud-img {
      left: 19.625rem;
      width: 5rem;
      top: -3.15rem;
    }
    .beach-container{
      min-height: 8.75rem;
    }
    .palm-trees {
      position: absolute;
      width: 9.7rem;
    }
  }
 @media only screen and (min-device-width : 1024px) {
    /* Styles */
    .sky-container {
      width: 12rem;
      right: 0.625rem;
      top: -9.375rem;
    }

    .sun-img {
      width: 7rem;
      top: -5rem;
      padding-top: 1rem;
      right: 11.12rem;
    }
    .cloud-img {
      left: 15.625rem;
      width: 5rem;
      top: -3.15rem;
    }
    .beach-container{
      min-height: 8.75rem;
    }
    .palm-trees {
      position: absolute;
      width: 9.7rem;
    }
  }

  /* iPads (landscape) ----------- */
  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px)
  and (orientation : landscape) {
    /* Styles */
  }

  /* iPads (portrait) ----------- */
  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px)
  and (orientation : portrait) {
    /* Styles */

  }

  /* Desktops and laptops ----------- */
  @media only screen
  and (min-width : 1224px) {
    /* Styles */
    .sky-container {
      width: 12rem;
      right: 0.625rem;
      top: -9.375rem;
    }

    .sun-img {
      position: absolute;
      width: 11rem;
      top: -8rem;
      padding-top: 1rem;
      right: 13.1rem;
    }
    .cloud-img {
      left: 28.625rem;
      top: -3rem;
    }
    .beach-container{
      min-height: 8.75rem;
    }
    .palm-trees {
      position: absolute;
      width: 9.7rem;
    }
  }

  /* Large screens ----------- */
  @media only screen
  and (min-width : 1824px) {
    /* Styles */
    .sky-container {
      width: 12rem;
      right: 0.625rem;
      top: -9.375rem;
    }

    .sun-img {
      position: absolute;
      width: 11rem;
      top: -8rem;
      padding-top: 1rem;
      right: 13.11rem;
    }
    .cloud-img {
      left: 41.625rem;
      top: -3rem;
    }
    .beach-container{
      min-height: 8.75rem;
    }
    .palm-trees {
      position: absolute;
      width: 9.7rem;
    }
  }

  /* iPhone 4 ----------- */
  @media
  only screen and (-webkit-min-device-pixel-ratio : 1.5),
  only screen and (min-device-pixel-ratio : 1.5) {
    /* Styles */
  }
  
`;

export default KilifiIconWrapper;
