import styled from "styled-components";

import fundsBg from "assets/images/zeb-pat-2-col-2.svg";
import {ethSafariColors} from "../../assets/theme/theme.js";

const CoinFundWrapper = styled.div`
  padding: 1rem;
  position: relative;
  background-color: rgba(255,255,255,0.65);

  .text-link {
    color: ${props => props.theme.ethSafariColors.darkAlt};
    font-size: 1.15rem;
  }

  //background-repeat: no-repeat;
  //background-size: inherit;
  //background-position: 82% 0px;
  //

  &::before {
    position: absolute;
    right: 0;
    top: 0%;
    width: 100%;
    height: 100%;
    content: " ";
  }

  .btn-wrapper {
    margin-top: 35px;
  }

  .btn-fill {
    border-radius: 3rem;
    font-size: 16px;
    padding: 18px 35px;
    background: ${props => props.theme.ethSafariColors.lightAlt};
    color: ${(props) => props.theme.ethSafariColors.blackTone};
  }

  .juicebox-link {
    transition: all 0.3s ease-in;
    min-height: 4rem;
    min-width: 7rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-family: Poppins-SemiBold;
    font-size: 1.4rem;
    padding: 0.5rem;

    &:hover {
      background: ${ethSafariColors.blackTone};
      // background: #FDA1FD;
      // color: #005B5C;
      color: ${ethSafariColors.whiteTone};
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .progressbar-wrapper {
    position: relative;
    height: 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    background: #fff;

    span {
      position: absolute;
      right: 0;
      bottom: -42px;
      color: ${props => props.theme.ethSafariColors.pink};
      font-size: 18px;
      margin: 0;
    }

    .progressbar {
      position: relative;
      width: 60%;
      height: 30px;
      border-radius: 20px;
      background: ${props => props.theme.ethSafariColors.darkAlt};
      background:linear-gradient(-45deg, #FDD2FD, #219DC9);
      span {
        bottom: -38px;
      }
    }
  }

  .payment-getway {
    margin-top: 85px;
    text-align: center;
    font-size: 20px;
    color: #fff;
    span {
      margin-right: 25px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .countBoxItem {
    min-width: 100px;
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
  }
  
  .countdown-support-banner {
    padding: 0.05rem 0.05rem 0.05rem 3.8rem;
    opacity: 0.6;
    background-color: ${props => props.theme.ethSafariColors.whiteTone};
    border-radius: 4rem;
    padding-left: 3.8rem;
    font-family: Poppins-Bold;
  }
  .countdown-wrapper {
    min-height:250px;
    background-color: transparent;
    /* background: url(${fundsBg}); */
    background-repeat: no-repeat;
    background-size: inherit;
    padding: 110px 0 140px 0;
    background-position: right;
    margin-bottom: 40px;
    font-family: Poppins-Bold;
    .text {
      font-family: Poppins-Bold;
      font-size: 14px;
      color: #fff;
      text-align: center;
      margin-bottom: 35px;
    }
    .countBox {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      opacity: 1;
      .count {
        line-height: 46px;
        font-weight: 700;
        margin-bottom: 16px;
      }
      .label {
       font-size: 14px;
       color: ${props => props.theme.ethSafariColors.dark};
      }
      .split {
        font-size: 40px;
        font-weight: 700;
        color: #fff;
        margin: 6px 8px 0 8px;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    background-size: 36%;
    background-position: 86% 41%;
    .heading {
      font-size: 25px;
      line-height: 40px;
      text-align: left;
    }
    .countdown-wrapper {
      .count {
        font-size: 45px;
      }
    }
    .countdown-support-banner {
      padding-left: 1rem;
      font-family: Poppins-Bold;
    }
  }

  @media only screen and (max-width: 912px) {
    background-position: 86% 70%;
    .countdown-support-banner {
      padding-left: 0;
      font-family: Poppins-Bold;
    }
    .coin-fund-content-left{
      margin-bottom: 80px;
    }
  }
  @media only screen and (max-width: 768px) {
    .countdown-support-banner {
      padding-left: 8.5rem;
      font-family: Poppins-Bold;
    }
    .countdown-wrap{
      width: 100%;
    }
  }
  @media only screen and (max-width: 480px) {
    background-color: ${props => props.theme.ethSafariColors.whiteTone};
    padding: 50px 0;

    .countdown-support-banner {
      padding-left: 1rem;
      font-family: Poppins-Bold;
    }
    .heading {
      font-size: 25px;
      line-height: 40px;
    }
    .countdown-wrapper {
      .split {
        display: none;
      }
      .count {
        font-size: 35px;
      } 
      .countBox{
        flex-direction: column-reverse;
      }
    }
  }
`;

export default CoinFundWrapper;
