import React from "react"

import { Container, Row, Col } from "reusecore/Layout"
import { SectionTitle } from "reusecore/SectionTitle"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"

import VideoEmbedWrapper from "./videoEmbed.style"

const VideoEmbed = () => {
  const youtubeVideoId = "UY5qI8iiSMc" // Replace with your YouTube video ID

  const youtubeEmbedUrl = `https://www.youtube.com/embed/${youtubeVideoId}`

  return (
    <VideoEmbedWrapper id="ethsafari2022Highlight">
      <Box className="videoembed-wrapper">
        <Container className="fluid">
          <Row>
            <Col className="sm-12 md-10 offset-md-1">
              <SectionTitle className="text-center">
                <Heading className="heading1">
                  Catch The ETHSafari Vibes..
                </Heading>
              </SectionTitle>
            </Col>
          </Row>
          <Row>
            <Col className="md-12">
              <Box className="videoembed-block">
                <iframe
                  src={youtubeEmbedUrl}
                  title="YouTube Video Embed"
                  allowFullScreen
                ></iframe>
              </Box>
            </Col>
          </Row>
        </Container>
      </Box>
    </VideoEmbedWrapper>
  )
}

export default VideoEmbed
