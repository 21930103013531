import React, { useState } from "react"
// import { Widget } from '@typeform/embed-react'
import { Container, Row, Col } from "reusecore/Layout"
import { SectionTitle } from "reusecore/SectionTitle"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import Image from "reusecore/Image"
import Button from "reusecore/Button"

import lgImg1 from "../../assets/images/2023/fgp_learning_3.png"

// import UserMapImage from "../../assets/images/partners/standerized-size/EncodexEthSafariDark1.png"
import UserMapWrapper from "./userMap.style"
import { ethSafariColors } from "../../assets/theme/theme.js"
import HackathonNotion from "../HackathonNotion/index.js"

const keyDates = [
  {
    label: "Registration by ",
    date: "1st September",
  },
  {
    label: "Late Registration by ",
    date: "8th September",
  },
  {
    label: "Submission Deadline by ",
    date: "15th September",
  },
  {
    label: "Demo Day on ",
    date: "22nd September",
  },
]
// const keyDateComponents = keyDates.map((item) => {
//   return (
//       <Text TextColor={ethSafariColors.blackTone} as={'div'} FontFamily={'Poppins-Medium'} FontSize={'1.4rem'}>
//         {item.label}
//         <Text TextColor={ethSafariColors.darkAlt} FontFamily={'Poppins-ExtraBold'} as={'b'}> {item.date}</Text>
//       </Text>
//   )
// })

const minWidth = 30
const images = [
  {
    id: 1,
    title: "learn",
    image: lgImg1,
    styleOpts: {
      minWidth: `${minWidth}rem`,
      minWidthLg: `${minWidth * 1.4 + 8}rem`,
      minWidthMd: `${minWidth * 1.2 + 3}rem`,
      minWidthSm: `${minWidth * 1.05 + 2}rem`,
      minWidthXs: `${minWidth * 1 + 0}rem`,
      zIndex: "31",
    },
  },
]

const UserMap = () => {
  const [currentImage] = useState(images[0])
  return (
    <UserMapWrapper id="Bootcathon">
      <Box className="usermap-wrapper">
        <Container className="fluid">
          <Row>
            <Col className="lg-7 md-10 xs-12">
              <Heading
                FontFamily={"Poppins-ExtraBold"}
                UpperCase={true}
                FontSize={"4rem"}
                style={{ textAlign: "center", lineHeight: "60px" }}
                HeadingColor={ethSafariColors.darkAlt}
              >
                Join The ETHSafari 2024 Hackathon{" "}
              </Heading>
              <Heading
                FontFamily={"Poppins-SemiBold"}
                FontSize={"2rem"}
                HeadingColor={ethSafariColors.darkAlt}
                UpperCase={true}
                style={{ textAlign: "center" }}
              >
                September 2024{" "}
              </Heading>
            </Col>
            <Col className="lg-4 md-3 xs-12">
              <Image
                className="banner_landingImg"
                src={currentImage.image}
                alt="Join The Bootckathon"
              />
            </Col>

            {/*<Row>*/}
            {/*  <Col className="md-12">*/}
            {/*    <Widget disableAutoFocus={true} id="Kxtcr1J5"/>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
          </Row>
          <Row>
            <Col className="sm-12 md-10 offset-md-1 xs-12">
              <SectionTitle className="text-center">
                {/* <Row>
                  <Col className="md-12">
                    <Box className="usermap-block">
                      <a href="https://www.encode.club/eth-safari-hackathon" target="_blank">
                        <img src={UserMapImage} alt="" />
                      </a>
                    </Box>
                  </Col>
                </Row> */}
                <br />
                <Heading UpperCase>
                  {/* <a href="https://www.encode.club/eth-safari-hackathon"> */}
                  <Text
                    FontFamily={"Poppins-Bold"}
                    TextColor={ethSafariColors.darkAlt}
                  >
                    ETHSafari Hackathon
                  </Text>
                  {/* </a> */}
                </Heading>
                <br />
                <Text
                  className={"text-name"}
                  FontSize={"1.2rem"}
                  FontFamily={"Poppins"}
                  TextColor={ethSafariColors.blackTone}
                >
                  Join us in learning and building with Ethereum starting
                  <Text
                    style={{ display: "inline" }}
                    TextColor={ethSafariColors.dark}
                    FontFamily={"Poppins-Medium"}
                  >
                    {" "}
                    September 2024
                  </Text>
                  . <br />
                  {/* We're getting ready for the ETHSafari conference the best way
                  we know how - building. Insightful builders circle and
                  hackathon agenda to get the Safari going! Starting
                  September 2024.
                  <br /> */}
                  <br />
                  <Text className={"text-description"} FontFamily={"Poppins"}>
                    The ETHSafari Hackathon is a
                    great way to connect with the latest in blockchain tech, we
                    are looking to educate by bringing people and platforms
                    together to build and scale on various decentralized platforms. Learn more below!
                    <br />
                    Registration OPEN
                    <br />
                    {/* <Text style={{display: 'inline'}} TextColor={ethSafariColors.dark} FontFamily={'Poppins-Medium'}>More details on registration coming soon!</Text> <br/> */}
                    <Button>
                      {/* <Text FontFamily={'Poppins-SemiBold'} className="btn-text" as="span">  </Text> */}
                      <a
                        href="https://www.ayalab.xyz/community/hackathons/d94556af-ba23-4aaa-9dfc-ec54927a2f89"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          fontFamily: "Poppins-Bold",
                          fontSize: "1.2rem",
                        }}
                      >
                        Register Here!
                      </a>
                    </Button>
                    
                  </Text>
                </Text>
                {/* <Text>
                  <div className={'hackathon-prize-container'}>
                    <Text as={'div'} FontFamily={'Poppins-Bold'} FontSize={'1.4rem'} TextColor={ethSafariColors.darkAlt} UpperCase={true}> $10,000+ in cash and prizes</Text>
                  </div>
                  <Text as={'div'} FontFamily={'Poppins-Bold'} FontSize={'1.5rem'} TextColor={ethSafariColors.darkAlt} UpperCase={true}> Key Dates</Text>
                  {keyDateComponents}
                </Text> */}
              </SectionTitle>
            </Col>
          </Row>
          <Row>
          <HackathonNotion/>
          </Row>
        </Container>
      </Box>
    </UserMapWrapper>
  )
}

export default UserMap
