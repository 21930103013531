import styled from "styled-components"

const AgendaWrapper = styled.div`
  background: ${(props) => (props.bgAgenda ? 'transparent' : 'white')};
  padding: 3rem 0;

  .agenda-section-row {
    margin: 0;
    padding: 0;
  }

  .agenda-section-title-container, .agenda-item-titles-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

  }

  .agenda-section-title {
    line-height: 6rem;
  }

  .agenda-section-title-container {
    display: flex;
    justify-content: space-between;
    min-height: 4rem;

  }

  .agenda-section-item-container, .agenda-section-list-container {
    display: flex;
    flex-direction: row;
  }

  .agenda-section-list-container {
    //background: white;
  }

  .agenda-section-item-container {
    flex: 3 4;
  }

  .agenda-item-titles-row {
    padding: 0;
    flex: 1 2;
  }

  .agenda-section-list-container {
    flex-direction: column;
  }

  .agenda-section-title, .agenda-date-title, agenda-location-title {
    display: flex;
    font-family: Poppins-Bold;
    align-items: flex-end;
    font-size: 3rem;
    opacity: 0.6;
    color: ${(props) => props.agendaTitleColor || props.theme.dark};
  }

  .agenda-date-title, .agenda-location-title {
    font-size: 1rem;
    font-family: Poppins-SemiBold;
    margin: 0;
    opacity: 0.6;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    color: ${(props) => props.theme.dark};
  }

  .agenda-section-title {
    font-size: 2rem;
  }

  .agenda-location-title {
    font-size: 2rem;
  }

  .agenda-section-img-container, .mobile-img, .mobile-agenda-title-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
  }

  .agenda-section-title-img {
    width: 15rem;
  }

  .agenda-section-item-img {
    padding: 0.5rem 0.5rem 0.5rem 4rem;
    width: 11rem;
  }

  .agenda-item-description,
  .agenda-item-date,
  .agenda-item-title {
    font-family: Poppins;
    font-size: 1.8rem;
    color: ${(props) => props.agendaTitleColor || props.theme.darkAlt};
  }

  .agenda-item-description {
    color: ${(props) => props.agendaTitleColor ? 'white' : props.theme.blackToneAlt};
  }

  .agenda-item-date, .agenda-item-title {
    font-size: 1.2rem;
  }

  .agenda-item-title {
    font-family: Poppins-SemiBold;
  }

  .agenda-item-data-container {
    display: flex;
    flex-direction: column;
    max-width: 1140px;
    padding-left: 0;
  }

  .agenda-item-description {
    font-size: 1.3rem;
    line-height: 47px;
  }

  .mobile-agenda-title-section {
    display: none;
  }


  /* Smartphones (portrait and landscape) ----------- */
  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 767px) {
    /* Styles */
    .mobile-agenda-title-section {
      display: flex;
      flex-direction: column;
    }

    .mobile-agenda-img-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .mobile-img {
      padding: 0;
    }

    .agenda-section-row {
      margin: 0;
      padding: 0;
    }

    .agenda-section-title {
      line-height: 2rem;
      font-size: 1.2rem;
      padding: 0 1rem 0 0;
      margin: 0;
    }

    .agenda-section-title-img {
      width: 7rem;
      display: flex;
      align-items: end;
    }

    .agenda-location-title {
      font-size: 1.3rem;
      //padding: 0 0 0 0.3rem;
    }

    .agenda-section-img-container, .mobile-agenda-title-section {
      align-items: baseline;
    }

    .agenda-section-img-container, .agenda-item-titles-row {
      display: none;
    }

    .agenda-item-title {
      padding: 0 0.5rem;
      margin: 0;
    }

    .agenda-item-data-container {
      width: 100%;
    }

    .agenda-section-item-img {
      //padding: 0.5rem 0.5rem 0.5rem 1rem;
      padding: 0;
      width: 3rem;
    }

    .agenda-item-date {
      font-size: 1rem;
    }

    .agenda-item-description {
      font-size: 0.8rem;
      line-height: 23px;
    }
  }

  /* Smartphones (landscape) ----------- */
  @media only screen
  and (min-width: 321px) {
    /* Styles */
  }

  /* Smartphones (portrait) ----------- */
  @media only screen
  and (max-width: 320px) {
    /* Styles */
  }

  // Tablet at 768
  /* iPads (portrait and landscape) ----------- */
  @media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px) {
    /* Styles */
    .agenda-section-title {
      font-size: 2rem;
      line-height: 2.5rem;
      min-width: 12rem;
      margin: 0;
    }

    .agenda-section-title-container {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem 0 0.5rem 2.6rem;
    }

    .agenda-section-title-img {
      width: 9rem;
    }
  }

  /* iPads (landscape) ----------- */
  @media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: landscape) {
    /* Styles */

  }

  /* iPads (portrait) ----------- */
  @media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: portrait) {
    /* Styles */
  }

  /* Desktops and laptops ----------- */
  @media only screen
  and (min-width: 1224px) {
    /* Styles */
    .agenda-section-title {
      font-size: 2.5rem;
      min-width: 12rem;
      margin: 0;
    }

    .agenda-section-title-container {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem 0 0.5rem 2.7rem;
    }

    .agenda-section-title-img {
      width: 14rem;
    }
  }

  /* Large screens ----------- */
  @media only screen
  and (min-width: 1824px) {
    /* Styles */
    .agenda-section-title {
      font-size: 5rem;
      min-width: 12rem;
      margin: 0;
    }

    .xl-margin-left {
      margin-left: 15.666667%;
    }
  }

  /* iPhone 4 ----------- */
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
    /* Styles */
  }





`
export default AgendaWrapper;
