import React from "react"

import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"

import data from "assets/data/service"
import ServiceSectionWrapper from "./service.style"
import {ethSafariColors} from "../../assets/theme/theme.js";
import agendaSamples from "../../assets/data/agenda/agendaSamples.js";

const Service = () => {
  return (
    <ServiceSectionWrapper id="service">
      <Container>
        <Row>
          <Col className="md-12">
            <Box className="service-item-wrapper">
              <Row>
                {data.services.map((service, index) => (
                  <Col className="lg-3 sm-6 xs-12" key={index}>
                    <Box className="service-item text-center">
                      <img src={service.icon} alt="cryptik service icon" />
                      <Heading as="h3">{service.title}</Heading>
                      <Text as="span">{service.body}</Text>
                    </Box>
                  </Col>
                ))}
              </Row>
            </Box>
          </Col>
        </Row>
      </Container>
    </ServiceSectionWrapper>
  )
}

const CustomService = (props) => {
  const {section} = props;
  const sections =  section.map((agendaItem, index) => {
    let description;
    if (agendaItem.title === agendaSamples.guideAgenda[1].title) {
      description = agendaItem.description
    }
    else {
      description = <Text TextColor={ethSafariColors.dark} as="span"
            FontFamily={'Poppins'}>{agendaItem.description}</Text>
    }
    return (
      <Col className="" key={index}>
        <Box className="service-item">
          <img src={agendaItem.icon} alt="ethsafari agenda section"/>
          <Heading HeadingColor={ethSafariColors.dark} FontFamily={'Poppins-Bold'}
                   as="h3">{agendaItem.title} {agendaItem.agendaDate ? `- ${agendaItem.agendaDate}` : null}</Heading>
          {description}
        </Box>
      </Col>
  )})
  return (
      <ServiceSectionWrapper id="service">
        <Container>
          <Row>
            <Col className="md-12">
              <Box className="service-item-wrapper">
                <Row>
                  {sections}
                </Row>
              </Box>
            </Col>
          </Row>
        </Container>
      </ServiceSectionWrapper>
  )
}

export {CustomService}

export default Service
