import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { PopupButton } from "@typeform/embed-react"
import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Badge from "reusecore/Badge"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import Image from "reusecore/Image"

import BannerImage from "assets/images/banners/banner-two/banner-two-thumb.svg"

import particleTopLeft from "assets/images/particles/banner/particle-top-left.png"
import stripesLandingTopLeft from "assets/images/2023/ArrowMutedColor_55pct.png"

import particleUnderLogo from "assets/images/particles/banner/particle-under-logo.png"
import particleTopRight from "assets/images/particles/banner/prticle-top-right.png"
import particleBottomLeft from "assets/images/particles/banner/particle-bottom-left.png"
import particleBottomRight from "assets/images/particles/banner/particle-bottom-right.png"

import { FaGithub } from "react-icons/fa"

import keyIcon from "assets/images/banners/banner-two/cryptik-banner-key-icon.svg"
import watchIcon from "assets/images/banners/banner-two/cryptik-banner-watch-icon.svg"
import heartIcon from "assets/images/banners/banner-two/cryptik-banner-heart-icon.svg"
import logo from "assets/images/banners/banner-two/logo-cashforcrypto-01.svg"
import image1 from "assets/images/2024/ethsafari-24.svg"

import lgImg1 from "assets/images/2023/FPG_8_headliner2_3.png"

import lgImg2 from "assets/images/2023/FGP_1_4.png"
import lgImg3 from "assets/images/2023/fgp_7_learning2.png"

import lgImg4 from "assets/images/2023/FPG_7_peepsImg.png"
import zebra2 from "assets/images/ether-diamond.gif"
import BannerWrapper from "./banner-two.style.js"
import Button from "../../reusecore/Button/index.js"
import { handleBuyTicketClick } from "../../utils/forms.utils.js"

import useLandingAnimation from "../../utils/hooks/useLandingAnimation.js"
import { ethSafariColors } from "../../assets/theme/theme.js"

// z-index: 31;
// /* width: 100%; */
// left: 4rem;
// top: 3rem;
// min-width: 15rem;
// min-width: 31rem;
// position: absolute;

// z-index: 31;
// width: 100%;
// left: -1rem;
// top: 0rem;
// min-width: 37rem;
// position: absolute;

const BannerTwo = () => {
  const minWidth = 10
  const images = [
    {
      id: 1,
      title: "learn",
      image: lgImg1,
      styleOpts: {
        width: "75%",
        minWidth: `${minWidth}rem`,
        minWidthLg: `${minWidth * 3 + 8}rem`,
        minWidthMd: `${minWidth * 2 + 3}rem`,
        minWidthSm: `${minWidth * 2 + 0}rem`,
        minWidthXs: `${minWidth * 1 + 0}rem`,
        zIndex: "31",
      },
    },
  ]
  const [currentImage, setCurrentImage] = useState(images[0])
  const { el, q, tl } = useLandingAnimation()
  useEffect(() => {}, [])
  return (
    <BannerWrapper id="bannertwo" bgImage={currentImage} ref={el}>
      <Container>
        <Image
          src={stripesLandingTopLeft}
          className="section__stripes top-left"
          alt="ethsafari particles"
        />
        <Row>
          <Image className="banner__thumb1" src={image1} alt="cash" />
          <Link to="https://safaridao.org/" className="">
            <Image className="banner__thumb2" src={zebra2} alt="cash" />
          </Link>
        </Row>
        <Row className="banner-text-row">
          <Col className="lg-7 md-10 xs-12">
            <Box className="banner-content">
              {/* <Heading className="heading1 highlighted dark-text-stroke">
                Get Real 🎯
              </Heading> */}

              <Heading className="heading1 highlighted">
                Welcome to The Greatest ETH event in Africa!
              </Heading>

              <Heading className="heading2 highlighted">
                Sun 8 - Sun 15 September 2024 in Kenya, East Africa.
              </Heading>
               <PopupButton
                id="dCyMrfrm"
                as={Button}
                style={{ width: "100%" }}
                fullWidth={true}
                disabled={true}
                onClick={handleBuyTicketClick}
              >
                {/* <FaGithub /> BUY  */}
                 <Text
                  FontFamily={"Poppins-SemiBold"}
                  style={{ margin: 0 }}
                  className="btn-text"
                  as="span"
                >
                  ETHSafari 2024 Tickets Live!
                </Text> 
              </PopupButton>
              {/* <Button disabled >
                <Text
                    FontFamily={"Poppins-SemiBold"}

                    style={{ margin: '5px 16px', opacity : 0.7}}
                    className="btn-text"
                    TextColor="#000"
                    as="span"
                  >
                    ETHSafari 2024 Tickets Live!
                  </Text>
              </Button> */}
              
            </Box>
          </Col>
          <Col className="lg-4 md-3 xs-12">
            <Image
              className="banner_landingImg"
              src={currentImage.image}
              alt="KaribuSafari"
            />
          </Col>
        </Row>
      </Container>
    </BannerWrapper>
  )
}

export default BannerTwo
