import React from "react"
import { Link } from "gatsby"
import CountdownTimer from "react-component-countdown-timer"
import juiceboxLogo from "../../assets/images/juice_logo.png"

import countdownLogo1 from "../../assets/images/2023/CountDown_1.png"

import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"

import Image from "reusecore/Image"
import Text from "reusecore/Text"
import { SectionTitle, SectionBackground } from "reusecore/SectionTitle"

import {
  FaBitcoin,
  FaCcMastercard,
  FaCcVisa,
  FaCcDiscover,
} from "react-icons/fa"

import CoinFundWrapper from "./coinFund.style"
import Heading from "../../reusecore/Heading/index.js"
import { ethSafariColors } from "../../assets/theme/theme.js"
import { SmallIconWrapper } from "../../assets/theme/CustomIcons/CustomIcon.style.js"
import safariGem from "../../assets/images/ether-diamond.gif"

function getSecondsTillETHSafari() {
  const t1 = new Date()
  const t2 = new Date(2024, 8, 9)
  const dif = t1.getTime() - t2.getTime()

  const Seconds_from_T1_to_T2 = dif / 1000
  return Math.abs(Seconds_from_T1_to_T2)
}

const CoinFund = () => {
  const settings = {
    count: getSecondsTillETHSafari(),
    showTitle: true,
    size: 60,
    labelSize: 14,
    backgroundColor: "transparent",
    color: `${ethSafariColors.dark}`,
    dayTitle: "Days",
    hourTitle: "Hours",
    minuteTitle: "Minutes",
    secondTitle: "Seconds",
    id: "countdownwrap",
  }
  return (
    <CoinFundWrapper id="safariOnJuicebox">
      <Container>
        <Row>
          {/* <Col className="lg-6 md-12 ">
              <Box className="coin-fund-content-left">
                <SectionTitle>
                  <SectionBackground>
                    <Heading FontFamily="Poppins-Bold" HeadingColor={ethSafariColors.dark}>
                      The SAFARI (ZC) Token
                    </Heading>
                  </SectionBackground>
                  <Text as="p" FontFamily="Poppins-Medium" TextColor={ethSafariColors.dark}>
                    The SAFARI token gives you access to exclusives at the ETHSafari event as well as after.
                    It serves as a way to support the Safari DAO (ETHSafari community) in its mission to bring people together to create a
                    better decentralized present and future <img style={{ width: '1.2rem'}} src={safariGem} alt="Safari Gem"/>
                  </Text>
              </SectionTitle>

              <Box className="btn-wrapper">
                <a href="https://juicebox.money/#/v2/p/64" target='_blank' className="btn btn-fill juicebox-link">
                  SAFARI on Juicebox
                  <SmallIconWrapper>
                    <img src={juiceboxLogo} alt="ETH Safari DAO Juicebox Link"/>
                  </SmallIconWrapper>
                </a>
              </Box>
            </Box>
          </Col> */}
          <Col className="lg-6 md-6 xs-12 countdown-wrap">
            <Box className="countdown-wrapper">
              <CountdownTimer {...settings} />
              {/* <Text TextColor={ethSafariColors.dark} className="countdown-support-banner"> Support our DAO by being a
                part of our SAFARI</Text> */}
            </Box>
            {/*<Box className="progressbar-wrapper">*/}
            {/*  <Box className="progressbar">*/}
            {/*    <Text as="span">$5,097</Text>*/}
            {/*  </Box>*/}
            {/*  <Text as="span">$11,931</Text>*/}
            {/*</Box>*/}

            {/*<Box className="payment-getway">*/}
            {/*  <Text as="span">*/}
            {/*    {" "}*/}
            {/*    <FaBitcoin />{" "}*/}
            {/*  </Text>*/}
            {/*  <Text as="span">*/}
            {/*    {" "}*/}
            {/*    <FaCcMastercard />{" "}*/}
            {/*  </Text>*/}
            {/*  <Text as="span">*/}
            {/*    {" "}*/}
            {/*    <FaCcVisa />{" "}*/}
            {/*  </Text>*/}
            {/*  <Text as="span">*/}
            {/*    {" "}*/}
            {/*    <FaCcDiscover />{" "}*/}
            {/*  </Text>*/}
            {/*</Box>*/}
          </Col>
          <Col className="lg-6 md-6 xs-12">
            <Image
              className="banner_landingImg"
              src={countdownLogo1}
              alt="Karibu Safari this September 2023"
            />
          </Col>
        </Row>
      </Container>
    </CoinFundWrapper>
  )
}

export default CoinFund
