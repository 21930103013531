import React from "react"
import { Link } from "gatsby"
import safariGem from "../../assets/images/ether-diamond.gif"
import { SectionTitle } from "reusecore/SectionTitle"
import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"

import data from "../../assets/data/stack/index.js"
import {CustomStackSectionWrapper} from "./stack.style"
// import {ethSafariColors} from "../../assets/theme/theme.js";
import Image from "../../reusecore/Image"

const Stack = () => {
  
  const [goldPartner1, ...otherPartners] = data.stack;
  const silverPartners = [...otherPartners];
  const bronze = [...data.bronzePartners];
  const standardPartners = [...data.smPartners];
  // const standardPartner = otherPartners.slice(9, otherPartners.length)
  return (
    <CustomStackSectionWrapper id="partners">
      <Container>
        <Row>
          {/*<Col className="offset-lg-3 offset-md-1 lg-6 md-10  sm-12">*/}
          <Col className="sm-12">
            <SectionTitle>
              <Heading className={'sponsor-thanks'} UpperCase={true} FontFamily={'Poppins-Bold'}> sponsors and partners in 2024</Heading>
              <Text FontFamily={'Poppins-Medium'} FontSize={'0.95rem'}>
                In 2024, we are still here to educate, doubling down on our mission to elevate web3 builders via a unique platform for decentralized innovation. The decentralized ecosystem continues to stay busy, now working towards its massive potential on the continent. We couldn't grow the ecosystem  without our the support from our partners and community, thank you!
                 The decentralized safari continues, builing a better decentralized future together <img style={{ width: '2.2rem', position: 'relative', padding: '0.5rem', top: '9px'}} src={safariGem} alt="Safari Gem"/>
              </Text>
              <Heading className={'intro-text'} UpperCase={true} FontFamily={'Poppins-Bold'}>presenting</Heading>
            </SectionTitle>
          </Col>
        </Row>
      </Container>
      <Row className={'partners-row'}>
        {/*<Col className="md-8 offset-md-2 xs-12">*/}
        <Col className="xs-12">
        {/* <Box className="language-image-anchor"> */}
        {/* <Text FontFamily={'Poppins-ExtraBold'} FontSize="2rem" TextColor="#fff">
                2023 Sponsors Announcement Incoming ⏰
              </Text>
              </Box> */}
          <Box className="language-image-anchor">
            <Link className={'anchor-sponsor'} target="_blank" to={goldPartner1.url}>
              <Image className={goldPartner1.id} src={goldPartner1.imageSrc} alt={goldPartner1.name}/>
            </Link>
            {/* <Link className={'anchor-sponsor'} target="_blank" to={goldPartner2.url}>
              <Image className={goldPartner2.id} src={goldPartner2.imageSrc} alt={goldPartner2.name}/>
            </Link>
            <Link className={'anchor-sponsor'} target="_blank" to={goldPartner3.url}>
              <Image className={goldPartner3.id} src={goldPartner3.imageSrc} alt={goldPartner3.name}/>
            </Link> */}
          </Box>
          <Box className="language-image-anchor">
            {silverPartners.map((item, index) => (
                <Link target="_blank" to={item.url} key={index}>
                  <Image className={item.id} src={item.imageSrc} alt={item.name} />
                </Link>
            ))}
          </Box>
          <Box className="language-image-anchor">
            {bronze.map((item, index) => (
                <Link target="_blank" to={item.url} key={index}>
                  <Image className={item.id} src={item.imageSrc} alt={item.name} />
                </Link>
            ))}
          </Box>
          {/* <Box className="language-image">
            {standardPartner.map((item, index) => ( 
                <Link target="_blank" to={item.url} key={index}>
                  <Image className={item.id} src={item.imageSrc} alt={item.name} />
                </Link>
            ))}
          </Box> */}
          <Box className="language-image">
            {standardPartners.map((item, index) => (
                <Link target="_blank" to={item.url} key={index}>
                  <Image className={item.id} src={item.imageSrc} alt={item.name} />
                </Link>
            ))}
          </Box>
          <SectionTitle>
            <Heading className={'intro-text media-partners-text'} UpperCase={true} FontFamily={'Poppins-Bold'}>OFFICIAL MEDIA PARTNERS</Heading>
          </SectionTitle>
          <Box className="language-image">
            {data.mediaPartners.map((item, index) => (
                <Link target="_blank" to={item.url} key={index}>
                  <Image className={item.id} src={item.imageSrc} alt={item.name} />
                </Link>
            ))}
          </Box>
        </Col>
      </Row>
    </CustomStackSectionWrapper>
  )
}

export default Stack;
