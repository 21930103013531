import './slider.scss'
import React, {useEffect} from "react";
import classNames from "classnames";
import Heading from "../../reusecore/Heading/index.js";

export default class CitiesSlider extends React.Component {
    constructor(props) {
        super(props);

        this.IMAGE_PARTS = 4;

        this.changeTO = null;
        this.AUTOCHANGE_TIME = 10000;

        this.state = { activeSlide: -1, prevSlide: -1, sliderReady: false };
    }

    componentWillUnmount() {
        window.clearTimeout(this.changeTO);
    }

    componentDidMount() {
        this.runAutochangeTO();
        setTimeout(() => {
            this.setState({ activeSlide: 0, sliderReady: true });
        }, 0);
    }

    runAutochangeTO() {
        this.changeTO = setTimeout(() => {
            this.changeSlides(1);
            this.runAutochangeTO();
        }, this.AUTOCHANGE_TIME);
    }

    changeSlides(change) {
        window.clearTimeout(this.changeTO);
        const { length } = this.props.slides;
        const prevSlide = this.state.activeSlide;
        let activeSlide = prevSlide + change;
        if (activeSlide < 0) activeSlide = length - 1;
        if (activeSlide >= length) activeSlide = 0;
        this.setState({ activeSlide, prevSlide });
    }

    render() {
        const { activeSlide, prevSlide, sliderReady } = this.state;
        return (
            <div className={classNames('slider', { 's--ready': sliderReady })}>
                {/*<p className="slider__top-heading">THE LATEST GREATEST CONFERENCE HAPPENING IN AFRICA</p>*/}
                <div className="slider__slides">
                    {this.props.slides.map((slide, index) => (
                        <div
                            className={classNames('slider__slide', { 's--active': activeSlide === index, 's--prev': prevSlide === index  })}
                            key={slide.city}
                        >
                            <div className="slider__slide-content">
                                <div className={`slider-ethsafari-titles`}>
                                    <Heading className="heading1">
                                        Get Real 🎯
                                    </Heading>
                                    <Heading className="heading2">
                                        Welcome to the latest greatest ETH event happening in Africa!
                                    </Heading>
                                </div>
                                <h3 className="slider__slide-subheading">{slide.country || slide.city}</h3>
                                <h2 className="slider__slide-heading">
                                    {slide.city.split('').map(l => <span>{l}</span>)}
                                </h2>
                                <p className="slider__slide-readmore">read more</p>
                            </div>
                            <div className="slider__slide-parts">
                                {[...Array(this.IMAGE_PARTS).fill()].map((x, i) => (
                                    <div className="slider__slide-part" key={i}>
                                        <div className="slider__slide-part-inner" style={{ backgroundImage: `url(${slide.img})` }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="slider__control" onClick={() => this.changeSlides(-1)} />
                <div className="slider__control slider__control--right" onClick={() => this.changeSlides(1)} />
            </div>
        );
    }
}

const slides = [
    {
        city: 'KILIFI',
        country: 'THE CONFERENCE',
        img: 'https://storage.googleapis.com/sme-connect-283212.appspot.com/venue-1.JPG',
    },
    {
        city: 'SPEAKER STAGES',
        img: 'https://storage.googleapis.com/sme-connect-283212.appspot.com/stage.jpg',
    },
    {
        city: 'PANEL DISCUSSIONS',
        country: 'ETH AND AFRICA',
        img: 'https://storage.googleapis.com/sme-connect-283212.appspot.com/venue-2.JPG',
    },
    // {
    //     city: 'Amsterdam',
    //     country: 'Netherlands',
    //     img: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/amsterdam.jpg',
    // },
    // {
    //     city: 'Moscow',
    //     country: 'Russia',
    //     img: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/moscow.jpg',
    // },
];

export {slides}



