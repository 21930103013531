import React from "react";
import {Col, Row} from "reusecore/Layout";
import ReactGA from 'react-ga';
import AgendaWrapper from "./agenda.style";
import Box from "../../reusecore/Box/index.js";
import Text from "../../reusecore/Text/index.js";
import FaCustomIcon from "../../assets/theme/CustomIcons/FaCustomIcon.js";
import {Card} from "../../reusecore/Card";
import {Container} from "../../reusecore/Layout/index.js";
import {ethSafariColors} from "../../assets/theme/theme.js";
import Heading from "../../reusecore/Heading/index.js";
import {SectionTitle} from "../../reusecore/SectionTitle";

const TRACKING_ID = "G-5L3PPX35LR"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

export const AgendaSectionTitle = (props) => {
  const {sectionTitle, safariLocation, locationIcon} = props;
  return (
      <>
        <Col className="md-7 xs-6 offset-md-2 xl-margin-left p-0">
          <Box className="agenda-section-title-container">
                    <Text className="agenda-section-title">
                        {sectionTitle ? sectionTitle : ''}
                    </Text>
                    {locationIcon}
            <Text as="span" className="agenda-location-title">
              {safariLocation ? safariLocation : ''}
            </Text>
          </Box>
        </Col>
        <Col className="lg-2 md-2 sm-0"></Col>
      </>
  );
}

export const CustomAgendaTitle = (props) => {
  const {sectionTitle, safariLocation, locationIcon} = props;
  return (
      <>
        <Container>
          <Row className="agenda-section-title-container">
            <Heading HeadingColor={ethSafariColors.pink} className="agenda-section-title">
              {sectionTitle ? sectionTitle : ''}
            </Heading>
            {locationIcon}
            <Text as="span" className="agenda-location-title">
              {safariLocation ? safariLocation : ''}
            </Text>
          </Row>
        </Container>

        <Col className="lg-2 md-2 sm-0"></Col>
      </>
  );
}

export const AgendaSectionItem = (props) => {

  const {agendaDate} = props.agendaItem;
  const mobileTitleSection = (
      <Box className="mobile-agenda-title-section">
        <Box className="mobile-agenda-img-container">
          <Box className="mobile-img">
            <FaCustomIcon className="agenda-section-item-img">
              <img src={props.agendaItem.icon}/>
                    </FaCustomIcon>
                </Box>
                <Text className="agenda-item-title" as="h1">
                        {props.agendaItem.title}
                </Text>
            </Box>
            <Text className="agenda-item-date">
                {agendaDate ? agendaDate : ''}
            </Text>
        </Box>);
    return (
        <Box className="agenda-section-item-container">
            <Box className="agenda-section-img-container">
                <FaCustomIcon className="agenda-section-item-img">
                    <img src={props.agendaItem.icon}/>
                </FaCustomIcon>
            </Box>
            <Col className="md-7 offset-md-1  agenda-item-data-container">
                <Box className="agenda-item-titles-row">
                    <Text>
                        <h1 className="agenda-item-title">
                            {props.agendaItem.title}
                        </h1>
                    </Text>
                    <Text className="agenda-item-date">
                        {agendaDate ? agendaDate : ''}
                    </Text>
                </Box>
                {mobileTitleSection}
                <Box>
                    <Text className="agenda-item-description">
                        {props.agendaItem.description}
                    </Text>
                </Box>
            </Col>
        </Box>
    );
}

const Agenda = (props) => {
    const {title, safariLocation, locationIcon, items, bgAgenda, agendaTitleColor, id} = props;
    return (
        <AgendaWrapper bgAgenda={bgAgenda} agendaTitleColor={agendaTitleColor} className={`${id}`} id="agenda">
                <Row className="agenda-section-row">
                    <Col className="lg-12 md-12 xs-12">
                        <AgendaSectionTitle
                            sectionTitle={title}
                            safariLocation={safariLocation}
                            locationIcon={locationIcon}
                        />
                    </Col>
                </Row>
                <Row className="agenda-section-row">
                    <Col className="lg-12 md-12 xs-12">
                      <Box className="agenda-section-list-container">
                        {items}
                      </Box>
                    </Col>
                </Row>
          {props.children}
        </AgendaWrapper>
    );
};

const CustomAgenda = (props) => {
  const {title, safariLocation, locationIcon, items, bgAgenda, agendaTitleColor, id} = props;
  return (
      <AgendaWrapper id="agenda" className={`${id}`}>
        <Card bgAgenda={bgAgenda} agendaTitleColor={agendaTitleColor}>
          <Row>
            <Col className="lg-12 md-12 xs-12">
              <CustomAgendaTitle
                  sectionTitle={title}
                  safariLocation={safariLocation}
                  locationIcon={locationIcon}
              />
            </Col>
          </Row>
          <Row>
            <Col className="lg-12 md-12 xs-12">
              <Box>
                {items}
              </Box>
            </Col>
          </Row>
          {props.children}
        </Card>
      </AgendaWrapper>
  );
};
export {CustomAgenda};
export default Agenda;

