import styled from "styled-components";

const VideoEmbedWrapper = styled.div`
  padding: 85px 0;

  .text-center {
    margin-bottom: 4rem;
  }

  .videoembed-block iframe {
    top: 0;
    left: 0;
    width: 100%;
    // height: 100%;
    height: 608px;
    border: none;
  }
  
  @media only screen and (max-width: 912px) {
    .heading{
      font-size: 34px;
    }
    .videoembed-block iframe {
      height: 408px;
      padding-left: 10px;
    }
  }

  @media only screen and (max-width: 568px) {
    .videoembed-wrapper {
      margin-top: 140px;
    }
    .heading{
      font-size: 23px;
    }.text-center {
      margin-bottom: 1rem;
    }
    .videoembed-block iframe {
      height: 208px;
    }
  }

  @media only screen and (max-width: 480px) {
    padding: 20px 0;
    .videoembed-block iframe {
    }
  }
`;

export default VideoEmbedWrapper;
