import styled from "styled-components"
import {ethSafariColors} from "../../assets/theme/theme.js";
import bgSponsors from "../../assets/images/2023/sponsorsBG_1_2.png"

const StackSectionWrapper = styled.div`
  padding: 85px 0;
  background-color: rgba(255,255,255,0.3);
  background: url('${bgSponsors}') no-repeat;
  background-size: cover;
  .title__wrapper {
    text-align: center;
  }
  
  .sponsor-thanks, .intro-text {
    font-size: 2.7rem;
    margin-bottom: 1rem;
    color: ${ethSafariColors.whiteTone}
  }
  
  .intro-text {
    font-size: 1.4rem;
    margin-bottom: 0.2rem;
    color: ${ethSafariColors.darkAlt}
  }
  .language-image, .language-image-anchor {
    display: flex;
    align-items: center;
    margin-top: 45px;
    justify-content: center;
    margin: 0.1rem 0.5rem;
    flex-wrap: wrap;
    a {
      margin: 0.5rem 0.6rem;
      display: flex;
      justify-content: center;
      &:hover {
        opacity: 0.7;
      }
      img {
        //height: 2.25rem;
      }
    }
  }
  


  .celo {
    width:24rem;
  }

  .icp {
    width: 22rem;
  }

  .avalanche, .base {
    width:8rem;
  }

  .emergo , .optimism{
    width: 26rem;
  }
 .polygon, .algorand, .filecoinFoundation,  .visa,  .ethereumFoundation, .aragon, .metamask {
    width: 19rem;
  }

  .utu, .digipesa, .lisk, .regen {
    width: 10rem;
  }

  .fonbnk, .adaverse  {
    width: 13rem;
  }

  .ethos, .starkware, .celoMentoOpera {
    width: 15rem;
  }

  .impactPlus, .nodo{
    width: 10rem;
  }
  .metaversal{
    width: 6rem;
  }
  .cointelegraph, .kenyanWallstreet, .cryptorunners, .aqualabs, .coinbase   {
    width: 9rem;
  }
  .unstoppableDomains, .marcoPoloExperience,  .streamETH, .africoin, .ethafrica  {
    width: 8rem;
  }
  .juicebox, .bitke, .itez,.banklessDao, .banklessAfrica {
    width: 6rem;
  }
  .near, .memoi, .coinedition, .asiatokenfund, .coinvoice, .cryptonewsz {
    width: 6rem;
  }
  .encodeClub, .welook, .dsrptd, .coinstelegram, .blocktides {
    width: 6rem;
  }

  .bitlipa, .nuzo {
    width: 4rem
  }  

  .honeycoin {
    width: 8rem;
  }
  .sharehub {
    width: 5rem;
  }

  .doingud, .jia {
    width: 4rem;
  }
  
  .daoPlanet, .abi, .abc {
    width: 10rem;
  }
  
  .media-partners-text {
    margin: 3rem 1rem;
  }
  
  .language-image-anchor{
    margin-top: 4rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-evenly;
    a {
      margin: 0.5rem 0.5rem;
      &:hover {
        opacity: 0.7;
      }
      img {
        //width: 28.5rem;
      }
    }
  }

  
  .language-image {
    a {
      margin: 0.5rem 0.8rem;
      
      img {
        //width: 28.5rem;
      }
    }
  }

  @media only screen and (max-width: 568px) {
    .language-image-anchor{
      margin-top: 0.8rem;
      margin-bottom: 1rem;
    }
    .box.language-image a img {
      //width: 85%;
    }

    .language-image {
      justify-content: center;
    }
  }

  @media only screen and (max-width: 480px) {
    padding: 20px 0;
    .box.language-image a img {
      //width: 70%;
    }
    .heading {
      font-size: 33px;
      line-height: 40px;
    }
  }
  @media only screen and (max-width: 375px) {
    .heading {
      font-size: 28px;
      line-height: 35px;
    }
  }
`

const CustomStackSectionWrapper = styled(StackSectionWrapper)`
  .partners-row {
    padding: 0 2rem;
  }
`;
export { CustomStackSectionWrapper };
export default StackSectionWrapper;
