import Box from "../../reusecore/Box/index.js";
import Text from "../../reusecore/Text/index.js";
import {ethSafariColors} from "../../assets/theme/theme.js";
import styled from "styled-components";
import FaqSectionWrapper from "../../sections/Faq/faq.style.js";
import React from 'react';
import CoinFundWrapper from "../../sections/CoinFund/coinFund.style.js";


const GeneralGuideWrapper = styled(CoinFundWrapper)`
  background: transparent;
  
  font-family: Poppins;

  &::before {
    display: none;
  }

  p {
    font-size: 0.95rem;
    font-family: Poppins;
    color: ${ethSafariColors.whiteTone};
  }

  .text-link {
    color: ${ethSafariColors.lightAlt};
  }
`;

function VolunteerGuide(props) {
  return (
      <GeneralGuideWrapper>
        <Box>
          <div>
            <p>Sign up for our volunteer list and find out how you can join in on making ETHSafari a success <a
                className="text-link" target="_blank" href="https://7u6j5ta3suk.typeform.com/ethsafari-sign2">Sign-up as
              a volunteer here</a>. We try to create open community bounties and challenges to give all members a chance
              in contributing towards our goals and objectives and rewarding successful participants</p>
          </div>
        </Box>
      </GeneralGuideWrapper>
  )
}

function DonationGuide(props) {
  return (
      <GeneralGuideWrapper>
        <Box>
          <div>
            <p>Support and gain access to event exclusives and discounts via <a className="text-link" target="_blank"
                                                                                href="https://juicebox.money/#/v2/p/64">our
              Juicebox fundraiser</a></p>
          </div>
        </Box>
      </GeneralGuideWrapper>
  )
}

export {VolunteerGuide, DonationGuide};

export default function TicketsGuide(props) {
  return (
      <GeneralGuideWrapper>
        <Box>
          <div>
            <div>
              <p>You can buy your ETHSafari ticket with Credit Card, Crypto, or M-pesa. <a className="text-link" target="_blank" href="https://7u6j5ta3suk.typeform.com/to/ZzRSKc9X">Buy the ticket here!
                </a>
              </p>
            </div>
            <div>
              <p>To buy a Crypto Ticket you will need an Ethereum Address, if you don't yet have one yet, <a className="text-link" target="_blank" href="https://docs.ethsafari.xyz/get-your-first-ethereum-wallet/how-to-install-metamask-wallet">read here how to get one in just 5 min!
                </a>
              </p>
            </div>
            {/* <div>
              <Text TextColor={ethSafariColors.whiteTone}><a className="text-link" target='_blank'
                                                             href="https://juicebox.money/#/v2/p/64">Juicebox Ticket
                Redemption</a> Supporters of our Juicebox project also get to convert their tokens into tickets! Tokens
                minted through Juicebox will also provide access to exclusive merch and content at the ETHSafari event.</Text>
            </div> */}
          </div>
        </Box>
      </GeneralGuideWrapper>

  )
}
