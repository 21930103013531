import React from "react"

import { Container, Row, Col } from "reusecore/Layout"
import { SectionTitle } from "reusecore/SectionTitle"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"

import VideoEmbedWrapper from "./videoEmbed.style"

const HackathonNotion = () => {
  const hackathonNotionLink = 'https://www.notion.so/safaridao/Get-Involved-in-the-ETHSafari-Hackathon-cc8ee301f65b4e1baa9e2ea54bc67f10'
  return (
    <VideoEmbedWrapper style={{width: '100%'}}>
      <Box styl className="videoembed-block" style={{width: '100%'}}>
                <iframe
                  // src={hackathonNotionLink}
                  width={"100%"}
                  src="https://ethsafari2024.super.site/" 
                  title="🛠️ ETHSafari Hackathon - September 8th - 10th 2024👩‍💻👨‍💻"
                  allowFullScreen
                ></iframe>
                
              </Box>
    </VideoEmbedWrapper>
  )
}

export default HackathonNotion
